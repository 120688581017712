import React, { useEffect, useState } from "react";
import Widget from "components/Widget/index";
import { useAuth } from "../../context/auth-context";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";
import { useQuery } from "react-query";
import {
  getStatisticsCategories,
  getStatisticsProducts
} from "../../services/querys/statistics";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import LineIndicator from "./LineIndicator";

const Rankings = ({ title }) => {
  const { dates } = useAuth();
  const [productsData, setProductsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [totalVisitsCategories, setTotalVisitsCategories] = useState(0);
  const [totalConversionsCategories, setTotalConversionsCategories] = useState(
    0
  );
  const [totalConversionsProducts, setTotalConversionsProducts] = useState(0);
  const [totalVisitsProducts, setTotalVisitsProducts] = useState(0);

  const enabled =
    window.location.pathname === "/estadisticas" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);
  /************************/
  /****** Productos ******/
  /************************/
  const {
    isLoading: isLoadingProducts,
    data: dataProducts,
    refetch: refetchProducts,
    isFetching: isFetchingProducts
  } = useQuery(
    ["statisticsProducts", dates],
    () =>
      getStatisticsProducts(
        dates[0].format("YYYY-MM-DD"),
        dates[1].format("YYYY-MM-DD")
      ),
    {
      // The query will not execute until the userId exists
      enabled,
      // state cache
      keepPreviousData: true,
      staleTime: 600000
    }
  );
  useEffect(() => {
    if (!isLoadingProducts) {
      if (dataProducts) {
        if (dataProducts.body) {
          let totalVisits = 0;
          let totalConversions = 0;
          dataProducts.body.map(item => {
            totalVisits += item.visits;
            totalConversions += item.conversions;
            return 0;
          });
          setTotalVisitsProducts(totalVisits);
          setTotalConversionsProducts(totalConversions);
          setProductsData(dataProducts.body);
        }
      }
    }
  }, [isLoadingProducts, isFetchingProducts]);

  /************************/
  /****** Categorias ******/
  /************************/
  const {
    isLoading: isLoadingCategories,
    data: dataCategories,
    refetch: refetchCategories,
    isFetching: isFetchingCategories
  } = useQuery(
    ["statisticsCategories", dates],
    () =>
      getStatisticsCategories(
        dates[0].format("YYYY-MM-DD"),
        dates[1].format("YYYY-MM-DD")
      ),
    {
      // The query will not execute until the userId exists
      enabled,
      // state cache
      keepPreviousData: true,
      staleTime: 600000
    }
  );
  useEffect(() => {
    if (!isLoadingCategories) {
      if (dataCategories) {
        if (dataCategories.body) {
          let totalVisits = 0;
          let totalConversions = 0;
          dataCategories.body.map(item => {
            totalVisits += item.visits;
            totalConversions += item.conversions;
            return 0;
          });
          setTotalVisitsCategories(totalVisits);
          setTotalConversionsCategories(totalConversions);
          setCategoriesData(dataCategories.body);
        }
      }
    }
  }, [isLoadingCategories, isFetchingCategories]);

  const crossMultiplication = (value, total) => {
    const x = (value * 100) / total;
    return `${x === 100 ? x : x.toFixed(2)}%`;
  };

  useEffect(() => {
    refetchCategories("statisticsCategories");
    refetchProducts("statisticsProducts");
  }, [dates]);

  return (
    <Widget styleName="gx-card-full gx-px-4 gx-py-4">
      <div className="ant-row-flex ">
        <h2 className="h2 gx-text-capitalize gx-mb-3">
          {" "}
          <CardHelpInfo texto={helpTexts[title]} />
        </h2>
      </div>

      <div className="gx-site-dash">
        <ul className="gx-line-indicator gx-fs-sm gx-pb-1 gx-pb-sm-0">
          {title === "productos" &&
            productsData.map((item, index) => (
              <li key={`${index}-productos`}>
                <LineIndicator
                  title={item.title}
                  width={crossMultiplication(item.visits, totalVisitsProducts)}
                  width2={crossMultiplication(
                    item.conversions,
                    totalConversionsProducts
                  )}
                  value={crossMultiplication(item.visits, totalVisitsProducts)}
                  value2={crossMultiplication(
                    item.conversions,
                    totalConversionsProducts
                  )}
                  color="primary"
                  color2="red"
                />
              </li>
            ))}
          {title === "categoria" &&
            categoriesData.map((item, index) => (
              <li key={`${index}-categoria`}>
                <LineIndicator
                  title={item.title}
                  width={crossMultiplication(
                    item.visits,
                    totalVisitsCategories
                  )}
                  width2={crossMultiplication(
                    item.conversions,
                    totalConversionsCategories
                  )}
                  value={crossMultiplication(
                    item.visits,
                    totalVisitsCategories
                  )}
                  value2={crossMultiplication(
                    item.conversions,
                    totalConversionsCategories
                  )}
                  color="primary"
                  color2="red"
                />
              </li>
            ))}
        </ul>
      </div>
    </Widget>
  );
};

export default Rankings;
