import request from "../utils/request";

export const listFreeShipping = (search, pageNumber) => {
  return request({
    endpoint: `/free-shipping/?search=${search}&page=${pageNumber}`
  });
};

export const balanceFreeShipping = () => {
  return request({
    endpoint: `/free-shipping/balance/`
  });
};
