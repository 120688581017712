import React, { useState } from "react";
import { Modal } from "antd";
const ModalImage = () => {
  const [previewVisible, setPreviewVisible] = useState(false);
  return (
    <div className="gx-d-flex gx-flex-column ">
      <p className="gx-mb-0">El primer banner solo se muestra en la home</p>
      <div className="gx-link" onClick={() => setPreviewVisible(true)}>
        Ver mas
      </div>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={require("assets/images/modulo-tienda.jpg")}
        />
      </Modal>
    </div>
  );
};
const helpTexts = {
  // estadisticas
  visitas: { title: "Visitas", text: "Número total de vistas a tus productos" },
  conversiones: {
    title: "Conversiones",
    text: "Número total de ventas convertidas de tus productos"
  },
  ticket: {
    title: "Ticket promedio",
    text: "Promedio de ventas realizadas sobre cantidad de pedidos"
  },
  historico: {
    title: "Ventas histórico",
    text: [
      "Detalle de ventas en un",
      <br />,
      " intervalo de tiempo determinado"
    ]
  },
  categoria: {
    title: "Visita por categoría",
    text: "Ranking de ventas por categoría de productos"
  },
  ventasIflash: {
    title: "Ventas Precios Relámpago",
    text: "Reporte de ventas por Precio Relámpago"
  },
  productos: {
    title: "Visita por Productos",
    text: "Ranking de ventas por productos"
  },

  // instant flash
  inicio: { title: "Fecha de inicio", text: "Inicio Precio Relámpago" },
  fin: {
    title: "Fecha de finalización",
    text: "Finalización Precio Relámpago"
  },
  descuento: {
    title: "Descuento",
    text: ["Descuento en porcentaje ", <br />, "de Precio Relámpago"]
  },
  limiteModal: {
    title: "Límite de unidades",
    text: ["Máximo de unidades a ", <br />, "vender con Precio relámpago"]
  },
  limite: {
    title: "Límite",
    text: "Máximo de unidades a vender con Precio relámpago"
  },

  // Envio gratis
  disponible: {
    title: "Saldo disponible",
    text: "Saldo aprobado para envíos sin cargo"
  },
  pendiente: {
    title: "Saldo pendiente",
    text: "Saldo pendiente de aprobar para envíos sin cargo"
  },
  utilizado: {
    title: "Saldo utilizado",
    text: "Saldo consumido Acción de envío sin cargo"
  },

  // Mi tienda
  nombreTienda: {
    title: "Nombre de la tienda",
    text: "Nombre de tienda visible en la home "
  },
  habilitar: { title: "Habilitar tienda", text: "Tienda visible en la Home" },
  banner: {
    title: "Banner",
    text: [
      "Máximo 5 banner",
      <br />,
      "Formatos permitidos: JPG, JPEG, PNG y GIF",
      <br />,
      "Tamaño mínimo: 1920x300 px."
    ]
  },
  ordenar: {
    title: "Ordenar",
    text: [<ModalImage />]
  }
};
export default helpTexts;
