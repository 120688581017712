import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Row, Col, message } from "antd";
import "moment/locale/es";
import { validatePassword } from "../../util/validators";
import { useAuth } from "../../context/auth-context";
import { useMutation } from "react-query";
import { updateUser } from "../../services/mutations/user";
import md5 from "md5";
import { LoadingOutlined } from "@ant-design/icons";
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 }
  }
};

const ModalConfig = ({ setIsVisible, isVisible }) => {
  const [form] = Form.useForm();
  const auth = useAuth();
  const updateUserMutation = useMutation(updateUser);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      email: auth.user.email,
    });
  },[])
  const onFinish = async () => {
    let variables = {};
    const password = form.getFieldValue("password");
    const email = form.getFieldValue("email");
    if (password !== "") {
      variables["password"] = md5(password);
    } else if (email !== "") variables["email"] = email;
    if (Object.entries(variables).length !== 0) {
      try {
        setIsLoading(true);
        const response = await updateUserMutation.mutateAsync(variables);
        if (!response.ok && response.status === 401)
          auth.setUserData({ token: null, user: null });
        else if (!response.ok) {
          message.error(response.body);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.success(response.body);
          setIsVisible(false);
          form.resetFields();
        }
      } catch (error) {
        console.error(error.message);
      }
    } else setIsVisible(false);
  };

  const validatePasswordForm = (_, inputValue) => {
    if (inputValue) {
      const {
        minLength,
        maxLength,
        special,
        upper,
        lower,
        complete
      } = validatePassword(inputValue);

      if (minLength && maxLength && special && upper && lower && complete) {
        return Promise.resolve();
      } else
        return Promise.reject(
          new Error(
            "Debe contener al menos una mayúscula, una minúscula, un caracter especial (!@#$%^&*,.) o número y minimo 8 caracteres"
          )
        );
    }
  };

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title={`Configurar mi cuenta`}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
        initialValues={
          {password:""}
        }
      >
        <FormItem
          name="password"
          label="Nueva contraseña"
          initialValue=""
          rules={[
            {
              validator: validatePasswordForm
            }
          ]}

          //hasFeedback
        >
          <Input.Password
            className="input-pass"
            type="password"
            placeholder="Nueva contraseña"
            allowClear
            autoComplete="new-password"
          />
        </FormItem>
        <FormItem
          name="resetPassword"
          label="Repita contraseña"
          initialValue=""
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !getFieldValue("password") ||
                  getFieldValue("password") === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Las contraseñas no coinciden!")
                );
              }
            })
          ]}
        >
          <Input.Password
            className="input-pass"
            type="password"
            placeholder="Repita la contraseña"
            allowClear
          />
        </FormItem>

        <FormItem
          name="email"
          label="Correo electrónico"
          initialValue=""
          rules={[
            {
              type: "email",
              message: "El correo electrónico no es valido."
            }
          ]}
        >
          <Input autoComplete="off"/>
        </FormItem>

        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setIsVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <Button
                type="primary"
                style={{ minWidth: 85 }}
                onClick={() => {
                  onFinish();
                }}
              >
                {isLoading ? (
                  <LoadingOutlined className="gx-fs-xl" />
                ) : (
                  " Guardar"
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalConfig;
