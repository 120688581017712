import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { ThunderboltFilled } from "@ant-design/icons";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { connect } from "react-redux";

const SidebarContent = ({ themeType, toggleCollapsedSideNav }) => {
  const [activeItem, setActiveItem] = useState(window.location.pathname);
  const toggleCollapsedSideNavMobile = () => {
    if (toggleCollapsedSideNav) toggleCollapsedSideNav();
  };
  const location = useLocation();
  useEffect(() => {
    var element = document.getElementById("flash-icono");
    element.classList.remove("anticon");
  });
  useEffect(() => {
    const activeLocal =
      location.pathname === "/" ? "/estadisticas" : location.pathname;
    setActiveItem(activeLocal);
  }, [location.pathname]);
  return (
    <Auxiliary>
      <SidebarLogo />
      <div className="gx-sidebar-content sidebar ">
        <CustomScrollbars className="gx-layout-sider-scrollbar gx-mt-4">
          <Menu
            defaultOpenKeys="/estadisticas"
            selectedKeys={activeItem}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            className=" gx-h-100 "
            onClick={e => {
              setActiveItem(e.key);
            }}
          >
            <Menu.Item key="/estadisticas">
              <Link to="/estadisticas" onClick={toggleCollapsedSideNavMobile}>
                <i className="icon icon-apps" />
                <span>Estadísticas</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/mi-tienda">
              <Link to="/mi-tienda" onClick={toggleCollapsedSideNavMobile}>
                <i className="icon icon-setting" />
                <span>Mi tienda</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/precios-relampago">
              <Link
                to="/precios-relampago"
                className="gx-d-flex gx-align-items-center"
                onClick={toggleCollapsedSideNavMobile}
              >
                <ThunderboltFilled
                  className="icon "
                  id="flash-icono"
                  style={{ lineHeight: 0 }}
                />
                <span style={{ marginLeft: 0 }}>Precios relámpago</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/envio-gratis">
              <Link to="/envio-gratis" onClick={toggleCollapsedSideNavMobile}>
                <i className="icon icon-sent " />
                <span>Envío Gratis</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/codigos">
              <Link to="/codigos" onClick={toggleCollapsedSideNavMobile}>
                <i className="icon icon-icon" />
                <span>Códigos</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </Auxiliary>
  );
};

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname };
};
export default connect(mapStateToProps)(SidebarContent);
