import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../../views/Auth/SignIn";
import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

import { useAuth } from "../../context/auth-context";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";

const App = props => {
  const auth = useAuth();

  const { layoutType, navStyle, locale } = props;

  const setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    link.className = "gx-style";
    document.body.appendChild(link);
    if (props.initURL === "") {
      props.setInitUrl(props.history.location.pathname);
    }
    const params = new URLSearchParams(props.location.search);

    if (params.has("theme")) {
      props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      props.onLayoutTypeChange(params.get("layout-type"));
    }
  }, []);

  setLayoutType(layoutType);

  setNavStyle(navStyle);

  const currentAppLocale = AppLocale[locale.locale];
  const [locationPath, setLocationPath] = useState(props.location.pathname);

  useEffect(() => {
    if (!auth.isAuthenticated) setLocationPath(props.location.pathname);
  }, []);
  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      getPopupContainer={node => {
        if (node) {
          return node.parentNode;
        }
        return document.body;
      }}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        {auth.isAuthenticated && !!localStorage.getItem(TOKEN_STORAGE_KEY) ? (
          <MainApp match={locationPath} />
        ) : (
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/" component={SignIn} />
            {!localStorage.getItem(TOKEN_STORAGE_KEY) && (
              <Redirect push to="/signin" />
            )}
          </Switch>
        )}
      </IntlProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { locale, navStyle, layoutType, authUser, initURL };
};
export default connect(mapStateToProps, {
  setInitUrl,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange
})(App);
