import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker } from "antd";
import Auxiliary from "util/Auxiliary";
import Visits from "./Visits";
import Conversions from "./Conversions";
import AvarageTickets from "./AverageTicket";
//import OrdenHistory from "./OrdenHistory";
import Rankings from "./Rankings";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import { getStatisticsStore } from "../../services/querys/statistics";
import { useQuery } from "react-query";
import { useAuth } from "../../context/auth-context";
import moment from "moment";

const { RangePicker } = DatePicker;

const TODAY = new moment();

const Statistics = () => {
  const { dates, setDates } = useAuth();
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YYYY"];

  const [generalData, setGeneralData] = useState([]);

  const enabled =
    window.location.pathname === "/estadisticas" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  // General: Visitas, Conversiones y Ticket promedio

  const {
    isLoading: isLoadingGeneral,
    data: dataGeneral,
    refetch: refetchGeneral,
    isFetching: isFetchingGeneral
  } = useQuery(
    ["statisticsStore", dates],
    () =>
      getStatisticsStore(
        dates[0].format("YYYY-MM-DD"),
        dates[1].format("YYYY-MM-DD")
      ),
    {
      // The query will not execute until the userId exists
      enabled,
      // state cache
      keepPreviousData: true,
      staleTime: 600000
    }
  );
  useEffect(() => {
    if (!isLoadingGeneral) {
      if (dataGeneral) {
        if (dataGeneral.body) setGeneralData(dataGeneral.body);
      }
    }
  }, [isLoadingGeneral, isFetchingGeneral]);

  return (
    <Auxiliary>
      <Row className="gx-mb-4 gx-justify-content-end">
        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
          <RangePicker
            separator={
              <div
                style={{ borderLeft: "1px solid ", height: 30 }}
                className="gx-text-light-grey"
              ></div>
            }
            locale={locale}
            format={dateFormatList}
            onChange={e => {
              setDates(e);
              refetchGeneral("statisticsStore");
            }}
            defaultValue={dates}
            disabledDate={current => {
              return current && current >= TODAY;
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
          <Visits data={generalData} />
        </Col>
        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
          <Conversions data={generalData} />
        </Col>
        <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
          <AvarageTickets data={generalData} />
        </Col>
        {/*
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <OrdenHistory dates={dates} title="historico" />
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <OrdenHistory dates={dates} title="ventasIflash" />
        </Col>
         */}
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Rankings dates={dates} title="categoria" />
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Rankings dates={dates} title="productos" />
        </Col>
      </Row>
    </Auxiliary>
  );
};
export default Statistics;
