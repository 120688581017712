import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import { avatarForm } from "../../services/mutations/myStore";
import { useMutation } from "react-query";
import { validarImagen } from "../../util/validators";

const UploadPicture = ({
  maxItems,
  title,
  files,
  setFileList,
  fileList,
  setResizedToBanner,
  setSize
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [resizedFile, setResizedFile] = useState(null);
  const handleCancel = () => setPreviewVisible(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sizeAvatar, setSizeAvatar] = useState({})
  const [isErrorSizeAvatar, setIsErrorSizeAvatar] = useState(false);
  const avatarMutation = useMutation(avatarForm);

  const handlePreview = file => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };
  useEffect(() => {
    if (files) setFileList(files);
  }, [files]);

  const updateImage = async resizedFile => {
    setIsLoading(false);
    let formData = new FormData();

    formData.append("avatar", resizedFile);

    if (sizeAvatar.width >= 512 && sizeAvatar.height >= 512) {
      setIsErrorSizeAvatar(false)
      try {
        const response = await avatarMutation.mutateAsync(formData);
        setFileList(
          fileList.map(file => {
            return { ...file, status: "success" };
          })
        );
        if (!response.ok) {
          console.error(response.body);
          setFileList(
            fileList.map(file => {
              return { ...file, status: "error", response:response.body};
            })
          );
        } else {
          message.success("Imagen cambiada satisfactoriamente");
        }
      } catch (error) {
        console.error(error.message);
      }
    }
    else {
      setIsErrorSizeAvatar(true)
      setFileList(
        fileList.map(file => {
          console.log(file)
          return { ...file, status: "error",response:"La imagen no cumple las restricciones" };
        })
      );
    }
    
  };
  useEffect(() => {
    if (!!resizedFile && isLoading && title === "logo") {
      updateImage(resizedFile);
    } else if (!!resizedFile && isLoading && title === "banner(s)") {
      setResizedToBanner(resizedFile);
      setFileList(
        fileList.map(file => {
          return { ...file, status: "success" };
        })
      );
    }
  }, [resizedFile]);

  const handleChange = async ({ fileList }) => {
    const files = [];
    fileList.map(file => files.push(file["originFileObj"]));
    setIsLoading(true);
    if (fileList.length > 0)
      if (fileList[0].status !== "uploading")
        await validarImagen(
          files,
          setResizedFile,
          title === "logo" ? 512 : null,
          title === "logo" ? 512 : null,
          title === "logo" ?setSizeAvatar : setSize
        );

    setFileList(
      fileList.map(file => {
        return { ...file, status: "uploading" };
      })
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Subir {title}</div>
    </div>
  );
  return (
    <div>
      <Upload
        listType="picture-card"
        style={{ maxWidth: 120 }}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        locale={{
          uploadError: "Ha ocurrido un error, intente nuevamente",
          uploading: "Cargando"
        }}
      >
        {fileList.length >= maxItems ? null : uploadButton}
      </Upload>
      {title === "logo" && fileList.length === 0 && (
        <div className="gx-text-orange gx-fs-sm">
          <p className="gx-mb-0">Formatos permitidos: JPG, JPEG, PNG y GIF</p>
          <p className="gx-mb-0">Tamaño mínimo: 512x512 px</p>
          <p className="gx-mb-0">Proporción recomendada: 1:1</p>
        </div>
      )}
      {isErrorSizeAvatar && title === "logo" &&fileList.length !== 0 && (
          <div className="gx-text-danger gx-fs-sm">
            Tamaño mínimo: 512x512 px
          </div>
        )}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UploadPicture;
