import React, { useEffect, useState } from "react";
import ModalIntantFlash from "./ModalInstantFlash";
import TableFlash from "./TableFlash";
import { useQuery } from "react-query";
import { listIFlash } from "../../services/mutations/instantFlash";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import { message } from "antd";

const InstantFlash = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [action, setAction] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagination, setTotalPagination] = useState(0);
  const [productList, setProductList] = useState([]);
  const [instantFlashSelected, setInstantFlashSelected] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const enabled =
    window.location.pathname === "/precios-relampago" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  const { isLoading, data, isFetching, refetch } = useQuery(
    ["listIFlash", currentPage, searchInput],
    () => listIFlash(searchInput, currentPage),
    { enabled, keepPreviousData: true, staleTime: 5000 }
  );

  useEffect(() => {
    if (!isLoading) {
      let arrayList = [];
      if (data)
        if (data.body.data) {
          data.body.data.map(item => {
            const {
              id,
              discount,
              endDate,
              startDate,
              unitsLimit,
              product
            } = item;
            return arrayList.push({
              key: id,
              id: id,
              discount,
              endDate,
              startDate,
              unitsLimit,
              stock: product.stock,
              image: `${process.env.REACT_APP_API_IMAGES}/${product.img}`,
              productName: product.title
            });
          });
          setTotalPagination(data.body.metadata.total);
          setProductList(arrayList);
        } else message.error("Ha ocurrido un error con los precios relámpago");
    }
  }, [isLoading, isFetching]);
  return (
    <>
      <TableFlash
        data={productList}
        buttonTitle="Agregar "
        onClickModal={() => {
          setIsVisible(true);
          setAction("create");
        }}
        isDateFilter={true}
        expandable
        loading={isLoading}
        setAction={data => setAction(data)}
        setIsVisible={data => setIsVisible(data)}
        setInstantFlashSelected={data => setInstantFlashSelected(data)}
        setTableIndex={data => setCurrentPage(data)}
        totalPagination={totalPagination}
        refetch={refetch}
        setSearchInput={data => setSearchInput(data)}
        tableIndex={currentPage}
      />
      <ModalIntantFlash
        action={action}
        isVisible={isVisible}
        setIsVisible={data => setIsVisible(data)}
        instantFlashSelected={instantFlashSelected}
        setInstantFlashSelected={data => setInstantFlashSelected(data)}
        refetch={refetch}
      />
    </>
  );
};

export default InstantFlash;
