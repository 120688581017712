import request from "../utils/request";

export const listGameCodeAction = (search, pageNumber) => {
  return request({
    endpoint: `/game-codes/?search=${search}&page=${pageNumber}`
  });
};
export const getByIdGameCodeAction = id => {
  return request({
    endpoint: `game-codes/${id}`
  });
};
export const listGameCodeProducts = id => {
  return request({
    endpoint: `game-codes/${id}/products/`
  });
};
export const listGameCodeItems = id => {
  return request({
    endpoint: `game-codes/${id}/codes/`
  });
};
