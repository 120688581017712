import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  DatePicker,
  Modal,
  Row,
  Col,
  Input,
  message
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
import { useMutation, useQuery } from "react-query";
import { addGameCodeAction } from "../../services/mutations/codes";
import { useAuth } from "../../context/auth-context";
import withLoaderButton from "../../hocs/StyledButton";
import moment from "moment";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import { getByIdGameCodeAction } from "../../services/querys/codes";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 }
  }
};

const ModalAddAction = ({
  setIsVisible,
  isVisible,
  action = "created",
  actionSelected,
  refetch
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [errors, setErrors] = useState();
  const titles = {
    edit: "Editar",
    create: "Nuevo"
  };
  const addActionMutation = useMutation(addGameCodeAction);
  const auth = useAuth();
  const LoaderButton = withLoaderButton(Button);

  const onFinish = async values => {
    setIsLoading(true);
    const { startDate, endDate, name } = values;

    const data = {
      name,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD ")
    };
    const id = action === "edit" ? actionSelected.key : "";
    const requestType = action === "edit" ? "PATCH" : "POST";
    const variables = [data, id, requestType];

    try {
      const response = await addActionMutation.mutateAsync(variables);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        setErrors(response.body);
      } else {
        refetch("listGameCodeAction");
        setIsLoading(false);
        setIsVisible(false);
        message.success(response.body);
        form.resetFields();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const enabled =
    action === "edit" && !!localStorage.getItem(TOKEN_STORAGE_KEY);

  const { isLoading: isloadingIF, data: selectedById } = useQuery(
    ["getByIdGameCodeAction", actionSelected.id],
    () => getByIdGameCodeAction(actionSelected.id),
    { enabled, keepPreviousData: true, staleTime: 5000 }
  );
  useEffect(() => {
    form.resetFields();
    if (action === "edit") {
      if (actionSelected && action === "edit" && !isloadingIF) {
        form.setFieldsValue({
          startDate: moment(selectedById.body.startDate),
          endDate: moment(selectedById.body.endDate),
          name: selectedById.body.name
        });
      }
    }
  }, [actionSelected, isloadingIF, isVisible]);
  const disabledDate = (current, day) => {
    return current && current <= day;
  };

  useEffect(() => {
    if (startDate) {
      const aux = new moment(startDate);
      form.setFieldsValue({
        endDate: aux.add(1, "day")
      });
    }
  }, [startDate]);
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => {
        form.resetFields();
        setIsVisible(false);
      }}
      centered
      title={`${titles[action]}  acción`}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <FormItem
          name="name"
          label="Nombre de la acción"
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <Input placeholder="Cyber monday" />
        </FormItem>
        <FormItem
          name={`startDate`}
          label={`Fecha de inicio`}
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <DatePicker
            allowClear
            format="DD-MM-YYYY"
            disabledDate={current =>
              disabledDate(
                current,
                moment()
                  .endOf("day")
                  .add(-1, "d")
              )
            }
            onChange={e => setStartDate(e)}
            value={startDate}
            locale={locale}
            placeholder="Seleccione la fecha"
          />
        </FormItem>
        <FormItem
          name={`endDate`}
          label={`Fecha de fin`}
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <DatePicker
            allowClear
            disabledDate={current => disabledDate(current, startDate)}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="Seleccione la fecha"
          />
        </FormItem>
        {errors && <div className="gx-text-danger">{errors}</div>}
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setIsVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <LoaderButton isLoading={isLoading}>Guardar</LoaderButton>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddAction;
