import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Codes from "../views/codes";
import FreeShipping from "../views/FreeShipping";
import InstantFlash from "../views/InstantFlash";
import MyStore from "../views/MyStore";
import Statistics from "../views/Statistics";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          exact
          path={`/`}
          component={() => {
            return <Statistics />;
          }}
        />
        <Route
          path={`/estadisticas`}
          component={() => {
            return <Statistics />;
          }}
        />
        <Route
          path={`/precios-relampago`}
          component={() => {
            return <InstantFlash />;
          }}
        />
        <Route
          path={`/envio-gratis`}
          component={() => {
            return <FreeShipping />;
          }}
        />
        <Route
          path={`/codigos`}
          component={() => {
            return <Codes />;
          }}
        />
        <Route
          path={`/mi-tienda`}
          component={() => {
            return <MyStore />;
          }}
        />
        <Redirect push to={"/"} />
        {match && <Redirect push to={match} />}
      </Switch>
    </div>
  );
};

export default App;
