import React, { useEffect, useState } from "react";
import ChartCard from "../../components/Statistics/ChartCard";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";

const Visits = ({ data }) => {
  const [visits, setVisits] = useState(0);
  const totalVisits = () => {
    let aux = 0;
    data.map(item => (aux += item.visits));
    setVisits(aux);
  };
  useEffect(() => {
    if (data.length > 0) totalVisits();
  }, [data]);
  return (
    <ChartCard
      prize={visits}
      //title="23"
      children={
        <ResponsiveContainer width="100%" height={75}>
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload)
                  if (payload.length > 0)
                    return (
                      <div className="tooltip-area-modificada">
                        <p className="gx-mb-1 gx-text-blue gx-fs-sm">
                          {payload[0].payload.statisticsAt}
                        </p>
                        <p className="gx-mb-0">
                          Ticket :{payload[0].payload.visits}
                        </p>
                      </div>
                    );
                return null;
              }}
            />
            <defs>
              <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#61b2e0" stopOpacity={0.9} />
                <stop offset="95%" stopColor="#228cff" stopOpacity={0.9} />
              </linearGradient>
            </defs>
            <Area
              dataKey="visits"
              strokeWidth={0}
              stackId="2"
              stroke="#4D95F3"
              fill="url(#color3)"
              fillOpacity={1}
            />
          </AreaChart>
        </ResponsiveContainer>
      }
      //styleName="up"
      desc={<CardHelpInfo texto={helpTexts.visitas} />}
    />
  );
};
export default Visits;
