export const validateEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"\u00C0-\u017F]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
export const validatePassword = password => ({
  minLength: password.length >= 6,
  maxLength: password.length <= 30,
  special: /[0-9!@#$%^&*,.]/.test(password),
  upper: /[A-Z]/.test(password),
  lower: /[a-z]/.test(password),
  complete: /^[a-zA-Z0-9!@#$%^&*,.]{8,30}$/.test(password)
});
export const validarImagen = (
  files,
  setResizedFile,
  maxHeight,
  maxWidth,
  setSize
) => {
  let archivo = files[0];

  if (archivo)
    if (
      archivo.type === "image/jpg" ||
      archivo.type === "image/jpeg" ||
      archivo.type === "image/png" ||
      archivo.type === "image/gif"
    ) {
      // Redimencionamos la imagen

      const file = files[0];
      const fileName = file.name;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canva = document.createElement("canvas");
          const ctx = canva.getContext("2d");
          const width = maxWidth && maxWidth < img.width ? maxWidth : img.width;
          const height =
            maxHeight && maxHeight < img.height ? maxHeight : img.height;
          canva.width = width;
          canva.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          setSize && setSize({ width, height });
          ctx.canvas.toBlob(
            blob => {
              setResizedFile(
                new File([blob], fileName, {
                  type: "image/jpeg",
                  lastModified: Date.now()
                })
              );
            },
            "image/jpeg",
            0.98
          );
        };
      };
    }
};
