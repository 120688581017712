import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";

const request = ({ method = "GET", endpoint, data = {} }) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  return fetch(`${process.env.REACT_APP_API_HOST}/${endpoint}`, {
    method,
    headers: {
      "Content-Type": "application/problem+json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
    .then(r =>
      r.json().then(data => ({ status: r.status, body: data, ok: r.ok }))
    )
    .then(obj => obj);
};
export default request;
