import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, InputNumber, message } from "antd";
import { moneyFormat } from "../utils/formatters";
import { useAuth } from "../../context/auth-context";
import { useMutation } from "react-query";
import { addBalanceFreeShipping } from "../../services/mutations/freeShipping";
import withLoaderButton from "../../hocs/StyledButton";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 }
  }
};

const ModalAddMoney = ({ setIsVisible, isVisible, refetch }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const LoaderButton = withLoaderButton(Button);
  const balanceFreeShippingMutation = useMutation(addBalanceFreeShipping);
  const auth = useAuth();
  const [errors, setErrors] = useState();
  const onFinish = async value => {
    setIsLoading(true);

    try {
      const response = await balanceFreeShippingMutation.mutateAsync(value);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        setErrors(response.body);
      } else {
        refetch("balanceFreeShipping");
        setIsLoading(false);
        setIsVisible(false);
        message.success(response.body);
        setValue(0);
        form.resetFields();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title="Agregar saldo"
    >
      <Form
        {...formItemLayout}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <div className="gx-text-orange  gx-fs-sm gx-mb-2">
          Siempre que ingresas saldo para realizar envíos sin cargo, podes verlo
          debajo de saldo disponible como "saldo pendiente" hasta que lo
          verifiquemos. Una vez verificado por marketing, pasará a formar parte
          de tu saldo disponible.
        </div>
        <FormItem
          name="cost"
          label="Saldo"
          rules={[
            {
              required: true,
              message: "Introduzca un monto!"
            }
          ]}
        >
          <InputNumber
            onChange={value => {
              setValue(value);
            }}
            type="number"
            placeholder="0"
          />
        </FormItem>

        <h2 className="gx-d-flex gx-justify-content-center gx-text-primary">
          {moneyFormat(value)}
        </h2>
        {errors && <div className="gx-text-danger">{errors}</div>}
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setIsVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <LoaderButton isLoading={isLoading}>Guardar</LoaderButton>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddMoney;
