import moment from "moment";

export const dateFormat = date => {
  return moment(date).format("YYYY-MM-DD");
};
export const moneyFormat = value => {
  return parseFloat(value)
    .toFixed(2)
    .replace(/\./g, ",") // eslint-disable-next-line
    .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
};
