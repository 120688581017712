import React, { useState, useEffect } from "react";
import ChartCard from "../../components/Statistics/ChartCard";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import helpTexts from "../../util/helpTexts";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";

const Conversions = ({ data }) => {
  const [conversions, setConversions] = useState(0);
  const totalConversions = () => {
    let aux = 0;
    data.map(item => (aux += item.conversions));
    setConversions(aux);
  };
  useEffect(() => {
    if (data.length > 0) totalConversions();
  }, [data]);
  return (
    <ChartCard
      prize={conversions}
      //title="08"
      children={
        <ResponsiveContainer width="100%" height={75}>
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload)
                  if (payload.length > 0)
                    return (
                      <div className="tooltip-area-modificada">
                        <p className="gx-mb-1 gx-text-blue gx-fs-sm">
                          {payload[0].payload.statisticsAt}
                        </p>
                        <p className="gx-mb-0">
                          Ticket :{payload[0].payload.conversions}
                        </p>
                      </div>
                    );
                return null;
              }}
            />

            <defs>
              <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ef4a4a" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#f90000" stopOpacity={0.8} />
              </linearGradient>
            </defs>
            <Area
              dataKey="conversions"
              strokeWidth={0}
              stackId="2"
              stroke="#FEEADA"
              fill="url(#color5)"
              fillOpacity={1}
            />
          </AreaChart>
        </ResponsiveContainer>
      }
      //styleName="down"
      desc={<CardHelpInfo texto={helpTexts.conversiones} />}
    />
  );
};
export default Conversions;
