import request from "../utils/request";
import requestBody from "../utils/requestBody";
export const addFreeShipping = data => {
  return requestBody({
    method: "POST",
    endpoint: `free-shipping/`,
    data: data
  });
};

export const deleteFreeShipping = id => {
  return request({
    method: "DELETE",
    endpoint: `free-shipping/${id}`
  });
};
export const addBalanceFreeShipping = data => {
  return requestBody({
    method: "POST",
    endpoint: `free-shipping/balance/`,
    data: data
  });
};
