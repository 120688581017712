import React, { createContext, useContext, useEffect, useState } from "react";
import {
  TOKEN_STORAGE_KEY,
  USER_DATA_STORAGE_KEY
} from "../constants/LocalStorageKeys";
import decode from "jwt-decode";
import moment from "moment";
const TODAY = new moment();
const MONTH_AGO = new moment().add(-30, "d");
const AuthContext = createContext();

function AuthProvider({ children }) {
  const [userData, setUserData] = useState({
    token: null,
    user: null
  });
  const isAuthenticated = !!userData.token;
  const [dates, setDates] = useState([MONTH_AGO, TODAY]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    const user = JSON.parse(localStorage.getItem(USER_DATA_STORAGE_KEY));

    if (isLoggedIn(token)) {
      setUserData({ token, user });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        isAuthenticated,
        dates,
        setDates
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const {
    userData,
    setUserData,
    isAuthenticated,
    dates,
    setDates
  } = useContext(AuthContext);

  return {
    isAuthenticated,
    token: userData.token,
    user: userData.user,
    setUserData,
    dates,
    setDates
  };
};
const isTokenExpired = token => {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
};

const getTokenExpirationDate = encodedToken => {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
};

const isLoggedIn = token => {
  if (token != null) {
    const tokenExpiredStatus = isTokenExpired(token);
    localStorage.setItem("isTokenExpired", tokenExpiredStatus);
    return !!token && !tokenExpiredStatus;
  } else {
    return false;
  }
};
export { AuthProvider, useAuth, AuthContext };
