const request = ({ method = "GET", endpoint, data = {} }) => {
  return fetch(`${process.env.REACT_APP_API_HOST}/${endpoint}`, {
    method,
    headers: {
      "Content-Type": "application/problem+json"
    },
    body: JSON.stringify(data)
  })
    .then(r =>
      r.json().then(data => ({ status: r.status, body: data, ok: r.ok }))
    )
    .then(obj => obj);
};
export default request;
