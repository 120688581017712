import React, { useEffect, useState } from "react";
import { Card, Table, Button, Col, Row, Input, message } from "antd";
import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import DeleteModal from "../../components/Modal/DeleteModal";
import { Image } from "antd";

import "moment/locale/es";
import { useMutation } from "react-query";
import { deleteFreeShipping } from "../../services/mutations/freeShipping";
import { useAuth } from "../../context/auth-context";

const TableFreeShipping = ({
  data,
  onClickAdditemModal,
  onClickBalancemodal,
  loading = false,
  totalPagination,
  setTableIndex,
  refetch,
  setSearchInput,
  tableIndex
}) => {
  const columns = [
    {
      title: "",
      dataIndex: "image",
      render: text => <Image width={50} style={{ maxHeight: 50 }} src={text} />
    },
    {
      title: "Nombre ",
      dataIndex: "productName",
      key: "productName"
    },

    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (text, _) => (
        <span>
          {text ? (
            <CheckOutlined className="gx-text-success gx-fs-lg" />
          ) : (
            <CloseCircleOutlined className="gx-text-danger gx-fs-lg" />
          )}
        </span>
      )
    },

    {
      key: "action",
      width: 20,
      render: (_, record) => (
        <DeleteOutlined
          className="gx-link gx-fs-xl"
          onClick={() =>
            DeleteModal(
              record.id,
              "este producto de Envío gratis",
              handleItemDelete
            )
          }
        />
      )
    }
  ];

  const auth = useAuth();
  const [freeShippingData, setFreeShippingData] = useState([]);
  const deleteIFlashMutation = useMutation(deleteFreeShipping);
  const handleItemDelete = async record => {
    try {
      const response = await deleteIFlashMutation.mutateAsync(record);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
        refetch("listFreeShipping");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    setFreeShippingData(data);
  }, [data]);

  return (
    <Card>
      <Row>
        <Col
          xl={17}
          lg={16}
          md={11}
          sm={11}
          xs={24}
          className="gx-d-flex gx-mb-4"
        >
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Button type="primary" onClick={onClickAdditemModal}>
                Agregar Producto
              </Button>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Button type="primary" onClick={onClickBalancemodal}>
                Agregar Saldo
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="gx-mb-4" xl={7} lg={8} md={13} sm={13} xs={24}>
          <Input
            id="search-free-shipping"
            placeholder="busqueda por nombre"
            onChange={e => {
              const inputValue = e.target.value;
              setSearchInput(inputValue);
              setTableIndex(1);
            }}
            allowClear
            className="gx-pl-3"
          />
        </Col>
      </Row>

      <Table
        className="gx-table-responsive components-table-demo-nested"
        columns={columns}
        locale={{
          emptyText: "No hay productos agregados"
        }}
        dataSource={freeShippingData}
        loading={loading}
        pagination={{
          onChange: current => {
            if (totalPagination) {
              setTableIndex(current);
            }
          },
          current: tableIndex,
          total: totalPagination,
          defaultPageSize: 30,
          position: [totalPagination > 30 ? "topRigth" : "none", "bottomRight"]
        }}
        scroll={{ x: window.innerWidth < 900 ? 550 : false }}
      />
    </Card>
  );
};

export default TableFreeShipping;
