import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";

import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";

const { Header } = Layout;

const Topbar = props => {
  const { width, navCollapsed, navStyle } = props;
  const [activePathname, setActivePathname] = useState(
    window.location.pathname
  );
  const location = useLocation();
  const changeTitle = () => {
    switch (location.pathname) {
      case "/estadisticas":
        setActivePathname("Estadísticas");
        break;
      case "/precios-relampago":
        setActivePathname("Precios relámpago");
        break;
      case "/envio-gratis":
        setActivePathname("Envío Gratis");
        break;
      case "/codigos":
        setActivePathname("Códigos");
        break;
      case "/mi-tienda":
        setActivePathname("Mi tienda");
        break;

      default:
        setActivePathname("");
        break;
    }
  };
  useEffect(() => {
    changeTitle();
  }, [location.pathname]);

  return (
    <Auxiliary>
      <Header>
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                props.toggleCollapsedSideNav(!navCollapsed);
              }}
            />
          </div>
        ) : null}
        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
          <img alt="" src={require("assets/images/iso.png")} width={22} />
        </Link>
        <h2 className="gx-mb-0 gx-ml-2">{activePathname}</h2>
        <ul className="gx-header-notifications gx-ml-auto">
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        </ul>
      </Header>
    </Auxiliary>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, navCollapsed, width } = settings;
  return { locale, navStyle, navCollapsed, width };
};

export default connect(mapStateToProps, {
  toggleCollapsedSideNav
})(Topbar);
