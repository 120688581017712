import * as React from "react";
import { AuthProvider } from "./auth-context";
import PropTypes from "prop-types";

function AppProviders({ children }) {
  return <AuthProvider>{children}</AuthProvider>;
}

AppProviders.propTypes = {
  children: PropTypes.any,
};

export default AppProviders;
