import React, { useEffect, useState } from "react";
import { Card, Table, Button, Col, Row, Input, Divider, message } from "antd";
import "moment/locale/es";
import moment from "moment";
import { useMutation } from "react-query";
import { deleteGameCodeAction } from "../../services/mutations/codes";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DeleteModal from "../../components/Modal/DeleteModal";
import { useAuth } from "../../context/auth-context";
import ModalAddCode from "./ModalAddCode";
import ModalAddProduct from "./ModalAddProduct";

const TableCodes = ({
  data,
  onClickAddAction,
  loading = false,
  totalPagination,
  setTableIndex,
  refetch,
  setSearchInput,
  tableIndex,
  setAction,
  setActionSelected
}) => {
  const [isVisibleAddCodes, setIsVisibleAddCodes] = useState(false);
  const [isVisibleAddProduct, setIsVisibleAddProduct] = useState(false);
  const [idAction, setIdAction] = useState(null);
  const [actionsData, setActionsData] = useState([]);
  const auth = useAuth();
  const deleteGameCodeActionMutation = useMutation(deleteGameCodeAction);

  const columns = [
    {
      title: "Nombre de la acción",
      dataIndex: "actionName",
      key: "actionName"
    },
    {
      title: "Fecha de inicio",
      dataIndex: "startDate",
      key: "startDate",
      width: 150,
      sorter: (a, b) =>
        new moment(a.startDate).format("YYYYMMDD") -
        new moment(b.startDate).format("YYYYMMDD"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => <span>{moment(text).format("DD-MM-YYYY")}</span>
    },
    {
      title: "Fecha de fin",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      sorter: (a, b) =>
        new moment(a.endDate).format("YYYYMMDD") -
        new moment(b.endDate).format("YYYYMMDD"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => <span>{moment(text).format("DD-MM-YYYY")}</span>
    },
    {
      title: "Códigos pendientes",
      dataIndex: "pendingCode",
      key: "pendingCode"
    },
    {
      title: "Códigos disponibles",
      dataIndex: "availableCode",
      key: "availableCode"
    },
    {
      title: "Productos asociados",
      dataIndex: "associatedProducts",
      key: "associatedProducts"
    },

    {
      key: "action",
      width: 200,
      render: (_, record) => (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
          <span
            className="gx-link"
            onClick={() => {
              setIsVisibleAddCodes(true);
              setIdAction(record.id);
            }}
          >
            Códigos
          </span>
          <Divider type="vertical" />
          <span
            className="gx-link"
            onClick={() => {
              setIsVisibleAddProduct(true);
              setIdAction(record.id);
            }}
          >
            Productos
          </span>
          <Divider type="vertical" />
          <EditOutlined
            className={` gx-fs-xl gx-link`}
            onClick={() => {
              onClickAddAction();
              setAction("edit");
              setActionSelected(record);
            }}
          />
          <Divider type="vertical" />
          <DeleteOutlined
            className="gx-link gx-fs-xl"
            onClick={() =>
              DeleteModal(record.id, "esta acción", handleItemDelete)
            }
          />
        </div>
      )
    }
  ];

  const handleItemDelete = async record => {
    try {
      const response = await deleteGameCodeActionMutation.mutateAsync(record);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
        refetch("listGameCodeAction");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    setActionsData(data);
  }, [data]);

  return (
    <Card>
      <Row>
        <Col
          xl={17}
          lg={16}
          md={11}
          sm={11}
          xs={24}
          className="gx-d-flex gx-mb-4"
        >
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Button type="primary" onClick={onClickAddAction}>
                Agregar acción
              </Button>
            </Col>
          </Row>
        </Col>
        <Col className="gx-mb-4" xl={7} lg={8} md={13} sm={13} xs={24}>
          <Input
            id="search-games-code"
            placeholder="busqueda por nombre"
            onChange={e => {
              const inputValue = e.target.value;
              setSearchInput(inputValue);
              setTableIndex(1);
            }}
            allowClear
            className="gx-pl-3"
          />
        </Col>
      </Row>

      <Table
        className="gx-table-responsive components-table-demo-nested"
        columns={columns}
        locale={{
          emptyText: "No hay acciones agregadas"
        }}
        dataSource={actionsData}
        loading={loading}
        pagination={{
          onChange: current => {
            if (totalPagination) {
              setTableIndex(current);
            }
          },
          current: tableIndex,
          total: totalPagination,
          defaultPageSize: 30,
          position: [totalPagination > 30 ? "topRigth" : "none", "bottomRight"]
        }}
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
      />

      {isVisibleAddCodes && (
        <ModalAddCode
          isVisible={isVisibleAddCodes}
          setIsVisible={data => setIsVisibleAddCodes(data)}
          idAction={idAction}
          refetchAction={refetch}
        />
      )}
      {isVisibleAddProduct && (
        <ModalAddProduct
          isVisible={isVisibleAddProduct}
          setIsVisible={data => setIsVisibleAddProduct(data)}
          idAction={idAction}
          refetchAction={refetch}
        />
      )}
    </Card>
  );
};

export default TableCodes;
