import React, { useEffect, useState } from "react";
import ChartCard from "../../components/Statistics/ChartCard";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";
const AvarageTickets = ({ data }) => {
  const [averageSales, setAverageSales] = useState(0);
  const totalAverageSales = () => {
    let aux = 0;
    data.map(item => (aux += item.averageSales));
    setAverageSales((aux / data.length).toFixed(2));
  };
  useEffect(() => {
    if (data.length > 0) totalAverageSales();
  }, [data]);
  return (
    <ChartCard
      prize={averageSales}
      //title="08"
      children={
        <ResponsiveContainer width="100%" height={75}>
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload)
                  if (payload.length > 0)
                    return (
                      <div className="tooltip-area-modificada">
                        <p className="gx-mb-1 gx-text-blue gx-fs-sm">
                          {payload[0].payload.statisticsAt}
                        </p>
                        <p className="gx-mb-0">
                          Ticket :{payload[0].payload.averageSales}
                        </p>
                      </div>
                    );
                return null;
              }}
            />

            <defs>
              <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f3d762" stopOpacity={0.9} />
                <stop offset="95%" stopColor="#fccc00" stopOpacity={0.9} />
              </linearGradient>
            </defs>
            <Area
              dataKey="averageSales"
              strokeWidth={0}
              stackId="2"
              stroke="#f3d762"
              fill="url(#color4)"
              fillOpacity={1}
            />
          </AreaChart>
        </ResponsiveContainer>
      }
      //styleName="down"
      desc={<CardHelpInfo texto={helpTexts.ticket} />}
    />
  );
};
export default AvarageTickets;
