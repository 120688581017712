import React, { useEffect, useState } from "react";
import ModalAddItem from "./ModalAddItem";
import ModalAddBalance from "./ModalAddBalance";
import TableFreeShipping from "./TableFreeShipping";
import IconWithTextCard from "../../components/Metric/IconWithTextCard";
import { Row, Col, message } from "antd";
import { moneyFormat } from "../utils/formatters";
import { useQuery } from "react-query";
import {
  balanceFreeShipping,
  listFreeShipping
} from "../../services/querys/freeShipping";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";
const FreeShipping = () => {
  const [isVisibleAddItem, setIsVisibleAddItem] = useState(false);
  const [isVisibleAddBalance, setIsVisibleAddBalance] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagination, setTotalPagination] = useState(0);
  const [productList, setProductList] = useState([]);
  const [balance, setBalance] = useState({ available: 0, pending: 0, used: 0 });
  const [searchInput, setSearchInput] = useState("");
  const enabled =
    window.location.pathname === "/envio-gratis" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  // Listado freeshipping
  const {
    isLoading: isLoadingList,
    data: listData,
    isFetching: isFetchingList,
    refetch: refetchList
  } = useQuery(
    ["listFreeShipping", currentPage, searchInput],
    () => listFreeShipping(searchInput, currentPage),
    { enabled, keepPreviousData: true, staleTime: 5000 }
  );
  useEffect(() => {
    if (!isLoadingList) {
      let arrayList = [];
      if (listData)
        if (listData.body.data) {
          listData.body.data.map(item => {
            const { id, product } = item;
            return arrayList.push({
              key: id,
              id: id,
              stock: product.stock,
              image: `${process.env.REACT_APP_API_IMAGES}/${product.img}`,
              productName: product.title
            });
          });
          setTotalPagination(listData.body.metadata.total);
          setProductList(arrayList);
        } else message.error("Ha ocurrido un error en envío gratis");
    }
  }, [isLoadingList, isFetchingList]);

  // Balance
  const {
    isLoading: isLoadingBalance,
    data: balanceData,
    isFetching: isFetchingBalance,
    refetch: refetchBalance
  } = useQuery(["balanceFreeShipping"], () => balanceFreeShipping(), {
    enabled,
    keepPreviousData: true,
    staleTime: 5000
  });
  useEffect(() => {
    if (!isLoadingBalance) {
      if (balanceData)
        if (balanceData.body) {
          setBalance(balanceData.body);
        } else message.error("Ha ocurrido con los saldos de envío gratis");
    }
  }, [isLoadingBalance, isFetchingBalance]);
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <IconWithTextCard
            iconColor="green"
            icon="revenue-new"
            title={moneyFormat(balance.available)}
            subTitle={<CardHelpInfo texto={helpTexts.disponible} />}
            pending={moneyFormat(balance.pending)}
          />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <IconWithTextCard
            iconColor="primary"
            icon="revenue-new"
            title={moneyFormat(balance.used)}
            subTitle={<CardHelpInfo texto={helpTexts.utilizado} />}
          />
        </Col>
      </Row>

      <TableFreeShipping
        data={productList}
        onClickAdditemModal={() => {
          setIsVisibleAddItem(true);
        }}
        onClickBalancemodal={() => {
          setIsVisibleAddBalance(true);
        }}
        setIsVisibleAddItem={data => setIsVisibleAddItem(data)}
        setTableIndex={data => setCurrentPage(data)}
        totalPagination={totalPagination}
        refetch={refetchList}
        setSearchInput={data => setSearchInput(data)}
        tableIndex={currentPage}
      />

      <ModalAddItem
        isVisible={isVisibleAddItem}
        setIsVisible={data => setIsVisibleAddItem(data)}
        refetch={refetchList}
      />
      <ModalAddBalance
        isVisible={isVisibleAddBalance}
        setIsVisible={data => setIsVisibleAddBalance(data)}
        refetch={refetchBalance}
      />
    </>
  );
};

export default FreeShipping;
