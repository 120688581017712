import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Image,
  Modal,
  Row,
  Col,
  DatePicker,
  InputNumber,
  message
} from "antd";

import SearchInput from "../../components/DataEntry/SearchInput";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
import moment from "moment";
import withLoaderButton from "../../hocs/StyledButton";
import {
  addInstantF,
  getByIdIFlash
} from "../../services/mutations/instantFlash";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../context/auth-context";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 }
  }
};

const ModalInstantFlash = ({
  setIsVisible,
  isVisible,
  action = "create",
  instantFlashSelected,
  refetch
}) => {
  const [form] = Form.useForm();
  const FormItem = Form.Item;
  const [errors, setErrors] = useState();
  const titles = {
    edit: "Editar",
    create: "Nuevo"
  };
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const LoaderButton = withLoaderButton(Button);
  const [startDate, setStartDate] = useState(null);
  const uploadIFlashMutation = useMutation(addInstantF);
  const auth = useAuth();

  const enabled =
    action === "edit" && !!localStorage.getItem(TOKEN_STORAGE_KEY);

  const { isLoading: isloadingIF, data: selectedById } = useQuery(
    ["idIFlash", instantFlashSelected.key],
    () => getByIdIFlash(instantFlashSelected.key),
    { enabled, keepPreviousData: true, staleTime: 5000 }
  );

  const disabledDate = (current, day) => {
    return current && current <= day;
  };

  const onFinish = async values => {
    setIsLoading(true);
    const { startDate, endDate, discount, unitsLimit } = values;
    if (!selectedProduct) {
      form.setFields([
        {
          name: "product",
          errors: ["Complete los campos."]
        }
      ]);
      setIsLoading(false);
      return;
    }

    const data = {
      idProduct: selectedProduct[0].id,
      startDate: moment(startDate).format("YYYY-MM-DD HH:mm"),
      endDate: moment(endDate).format("YYYY-MM-DD HH:mm"),
      discount,
      unitsLimit
    };
    const id = action === "edit" ? instantFlashSelected.key : "";
    const requestType = action === "edit" ? "PATCH" : "POST";
    const variables = [data, id, requestType];

    try {
      const response = await uploadIFlashMutation.mutateAsync(variables);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        setErrors(response.body);
      } else {
        refetch("listIFlash");
        setIsLoading(false);
        setIsVisible(false);
        message.success("Precio relámpago creado satisfactoriamente");
        setSelectedProduct("");
        form.resetFields();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    form.resetFields();
    setSelectedProduct("");
    if (action === "edit") {
      if (instantFlashSelected && action === "edit" && !isloadingIF) {
        form.setFieldsValue({
          startDate: moment(selectedById.body.startDate),
          endDate: moment(selectedById.body.endDate),
          discount: selectedById.body.discount,
          unitsLimit: selectedById.body.unitsLimit
        });
        setSelectedProduct([
          {
            id: selectedById.body.product.id,
            title: selectedById.body.product.title,
            img: selectedById.body.product.img
          }
        ]);
      }
    }
  }, [instantFlashSelected, isloadingIF, isVisible]);

  useEffect(() => {
    if (startDate) {
      const aux = new moment(startDate);
      form.setFieldsValue({
        endDate: aux.add(1, "hour")
      });
    }
  }, [startDate]);

  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      footer={null}
      onCancel={() => {
        setSelectedProduct("");
        form.resetFields();
        setIsVisible(false);
      }}
      centered
      title={`${titles[action]}   precio relámpago `}
      style={{ minWidth: window.innerWidth > 550 ? 600 : 200 }}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        {action === "create" && (
          <div className="gx-text-orange  gx-fs-sm gx-mb-2">
            Tu nuevo precio relámpago estará disponible en libreopcion.com en
            unos instantes o a la hora y fecha programada.
          </div>
        )}

        <FormItem name="product" label="Nombre del producto">
          <SearchInput
            placeholder="Indique el producto"
            setSelectedValue={data => setSelectedProduct(data)}
            route="instant-flash"
          />
        </FormItem>
        {selectedProduct && (
          <div className="product-selected  gx-py-3 gx-mb-3">
            <Image
              width={100}
              src={`${process.env.REACT_APP_API_IMAGES}/${selectedProduct[0].img}`}
            />
            <span>{selectedProduct[0].title}</span>
          </div>
        )}
        <FormItem
          name="startDate"
          label={<CardHelpInfo texto={helpTexts.inicio} />}
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <DatePicker
            allowClear
            format="DD-MM-YYYY HH:mm"
            showTime
            disabledDate={current =>
              disabledDate(
                current,
                moment()
                  .endOf("day")
                  .add(-1, "d")
              )
            }
            onChange={e => setStartDate(e)}
            locale={locale}
            placeholder="Seleccione fecha y hora"
          />
        </FormItem>
        <FormItem
          name="endDate"
          label={<CardHelpInfo texto={helpTexts.fin} />}
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <DatePicker
            allowClear
            disabledDate={current => disabledDate(current, startDate)}
            format="DD-MM-YYYY HH:mm"
            showTime
            showNow={false}
            locale={locale}
            placeholder="Seleccione fecha y hora"
          />
        </FormItem>

        <FormItem
          name="discount"
          label={<CardHelpInfo texto={helpTexts.descuento} />}
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <InputNumber
            max={100}
            min={0}
            formatter={value => `${value}%`}
            parser={value => value.replace("%", "")}
          />
        </FormItem>

        <FormItem
          name="unitsLimit"
          label={<CardHelpInfo texto={helpTexts.limiteModal} />}
          rules={[
            {
              required: true,
              message: "Complete los campos."
            }
          ]}
        >
          <InputNumber min={0} placeholder="0" />
        </FormItem>
        {errors && <div className="gx-text-danger">{errors}</div>}

        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setSelectedProduct("");
                  setIsVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <LoaderButton isLoading={isLoading}>Guardar</LoaderButton>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalInstantFlash;
