import request from "../utils/request";
import requestBody from "../utils/requestBody";

// Acciones
export const addGameCodeAction = variables => {
  return requestBody({
    method: variables[2],
    endpoint: `game-codes/${variables[1]}`,
    data: variables[0]
  });
};

export const deleteGameCodeAction = id => {
  return request({
    method: "DELETE",
    endpoint: `game-codes/${id}`
  });
};

// Productos
export const addGameCodeProducts = data => {
  return request({
    method: "POST",
    endpoint: `game-codes/${data.idAction}/products/${data.idProduct}`
  });
};
export const deleteGameCodeProducts = data => {
  return request({
    method: "DELETE",
    endpoint: `game-codes/${data.idAction}/products/${data.idProduct}`
  });
};

// Codigos

export const addGameCodeItems = data => {
  console.log(data);
  return requestBody({
    method: "POST",
    endpoint: `game-codes/${data.idAction}/codes/multiple`,
    data: data.codes
  });
};
export const deleteGameCodeItems = data => {
  return request({
    method: "DELETE",
    endpoint: `game-codes/${data.idAction}/codes/${data.idCode}`
  });
};
