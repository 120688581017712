import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col, message } from "antd";
import "moment/locale/es";
import UploadPicture from "../../components/Upload/UploadPicture";
import { bannerForm } from "../../services/mutations/myStore";
import { useMutation } from "react-query";
import withLoaderButton from "../../hocs/StyledButton";
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 }
  }
};

const ModalImage = ({ setIsVisible, isVisible, refetchBanner }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [banner, setBanner] = useState([]);
  const [size, setSize] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const bannersMutation = useMutation(bannerForm);
  const [isErrorSize, setIsErrorSize] = useState(false);
  const LoaderButton = withLoaderButton(Button);

  const onFinish = async () => {
    setIsLoading(true);
    setIsErrorSize(false);

    if (size.width >= 1920 && size.height >= 300) {
      try {
        let formData = new FormData();
        formData.append("file", banner);
        const response = await bannersMutation.mutateAsync(formData);
        if (!response.ok) {
          console.error(response.body);
          setFileList(
            fileList.map(file => {
              return { ...file, status: "error" };
            })
          );
          await message.error(response.body);
          setIsVisible(false);
          setIsLoading(false);
        } else {
          message.success(response.body);
          refetchBanner("banners");
          setIsLoading(false);

          setIsVisible(false);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setFileList([]);
      }
    } else {
      setIsErrorSize(true);
      setIsLoading(false);
    }
    form.resetFields();
  };

  useEffect(() => {
    setIsErrorSize(false);
  }, [banner]);
  const closeModal = () => {
    form.resetFields();
    setIsVisible(false);
    setFileList([]);
    setIsErrorSize(false);
  };
  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      footer={null}
      onCancel={() => closeModal()}
      centered
      title={`Agregar banners`}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <div className="gx-text-orange gx-fs-sm">
          <p className="gx-mb-0">Formatos permitidos: JPG, JPEG, PNG y GIF</p>
          <p>Tamaño mínimo: 1920x300 px</p>
        </div>
        <FormItem name="banner" label="Banners" hasFeedback>
          <UploadPicture
            maxItems={1}
            title="banner(s)"
            setFileList={data => setFileList(data)}
            fileList={fileList}
            setResizedToBanner={data => setBanner(data)}
            setSize={data => setSize(data)}
          />
        </FormItem>

        {isErrorSize && (
          <div className="gx-text-danger">
            La imagen debe ser mínimo 1920x300px
          </div>
        )}
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button type="secondary" onClick={() => closeModal()}>
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <LoaderButton isLoading={isLoading}>Guardar</LoaderButton>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalImage;
