import React, { useEffect, useState } from "react";
import { Button, Table, Modal, message } from "antd";
import { WithContext as ReactTags } from "react-tag-input";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { listGameCodeItems } from "../../services/querys/codes";
import { useAuth } from "../../context/auth-context";
import {
  addGameCodeItems,
  deleteGameCodeItems
} from "../../services/mutations/codes";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const ModalAddCode = ({ setIsVisible, isVisible, idAction, refetchAction }) => {
  const [tags, setTags] = useState([]);
  const [tagsCode, setTagsCode] = useState([]);
  const [codeList, setCodeList] = useState([]);
  const auth = useAuth();

  const addCodesToActionMutation = useMutation(addGameCodeItems);

  const enabled =
    window.location.pathname === "/codigos" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  const { isLoading, data, isFetching, refetch } = useQuery(
    ["listGameCodeItems", idAction],
    () => listGameCodeItems(idAction),
    { enabled, keepPreviousData: true, staleTime: 50000 }
  );

  useEffect(() => {
    if (!isLoading) {
      let arrayList = [];
      if (data)
        if (data.body) {
          data.body.map(item => {
            return arrayList.push({
              key: item.id,
              id: item.id,
              code: item.code,
              status: item.status,
              dateDelivered: item.dateDelivered
            });
          });
          setCodeList(arrayList);
        } else
          message.error(
            "Ha ocurrido un error al cargar los códigos de esta acción"
          );
    }
  }, [isLoading, isFetching]);

  const addCodesToAction = async () => {
    try {
      const response = await addCodesToActionMutation.mutateAsync({
        idAction,
        codes: { items: tagsCode }
      });
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        refetch("listGameCodeItems");
        refetchAction("listGameCodeAction");
        message.success(response.body);
        handleDeleteAll();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const deleteCodeMutation = useMutation(deleteGameCodeItems);
  const handleItemDelete = async record => {
    try {
      const response = await deleteCodeMutation.mutateAsync({
        idAction,
        idCode: record
      });
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
        refetch("listGameCodeItems");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleDeleteAll = () => {
    setTags([]);
    setTagsCode([]);
  };

  const handleDelete = i => {
    setTags(tags.filter((_, index) => index !== i));
    setTagsCode(tagsCode.filter((_, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags(state => [...state, tag]);
    setTagsCode(state => [...state, tag.text]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    // re-render
    setTags(newTags);

    const newTagsCode = tagsCode.slice();
    newTagsCode.splice(currPos, 1);
    newTagsCode.splice(newPos, 0, tag);
    setTagsCode(newTagsCode);
  };
  const columns = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code"
    },
    {
      title: "Fecha enviado",
      dataIndex: "dateDelivered",
      key: "dateDelivered",

      render: (text, record) => (
        <span>{!record.status && moment(text).format("DD-MM-YYYY ")}</span>
      )
    },
    {
      title: "Acciones",
      key: "action",
      width: 50,
      render: (_, record) => (
        <DeleteOutlined
          className={` gx-fs-md ${
            !record.status ? "gx-text-light-grey gx-cursor-default" : "gx-link"
          }`}
          onClick={() => {
            if (record.status)
              DeleteModal(record.id, "este código", handleItemDelete);
          }}
        />
      )
    }
  ];

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title="Agregar códigos"
    >
      <div className="gx-d-flex gx-align-items-center">
        <ReactTags
          inline={true}
          classNames={{
            tagClass: "ant-tag-lime",
            tagInputField: "tag-input-field gx-my-1 ",
            selected: "selected-class-tags",
            tag: "tagClass",
            remove: "tag-remove"
          }}
          tags={tags}
          placeholder="ej:JBSKD34,ASSdSF"
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          delimiters={delimiters}
          allowDeleteFromEmptyInput
        />
        <div className="gx-ml-2">
          {tags.length > 0 && (
            <Button className="gx-my-1" onClick={() => handleDeleteAll()}>
              Limpiar Todo
            </Button>
          )}
          <Button className="gx-my-1" onClick={() => addCodesToAction()}>
            Agregar
          </Button>
        </div>
      </div>
      <Table
        className="gx-table-responsive components-table-demo-nested"
        columns={columns}
        dataSource={codeList}
        locale={{
          emptyText: "No hay códigos agregadas"
        }}
        scroll={{ x: window.innerWidth < 500 ? 450 : false }}
      />
    </Modal>
  );
};

export default ModalAddCode;
