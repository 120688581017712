import React, { useEffect, useState } from "react";
import { Form, Image, Modal, message } from "antd";
import SearchInput from "../../components/DataEntry/SearchInput";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import DeleteModal from "../../components/Modal/DeleteModal";
import { useMutation, useQuery } from "react-query";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import { listGameCodeProducts } from "../../services/querys/codes";
import {
  addGameCodeProducts,
  deleteGameCodeProducts
} from "../../services/mutations/codes";
import { useAuth } from "../../context/auth-context";
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 }
  }
};

const ModalAddProduct = ({
  setIsVisible,
  isVisible,
  idAction,
  refetchAction
}) => {
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const auth = useAuth();
  const addProductToActionMutation = useMutation(addGameCodeProducts);

  const enabled =
    window.location.pathname === "/codigos" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  const { isLoading, data, isFetching, refetch } = useQuery(
    ["listGameCodeProducts", idAction],
    () => listGameCodeProducts(idAction),
    { enabled, keepPreviousData: true, staleTime: 50000 }
  );

  useEffect(() => {
    if (!isLoading) {
      let arrayList = [];
      if (data)
        if (data.body) {
          data.body.map(item => {
            return arrayList.push({
              key: item.id,
              id: item.id,
              src: `${process.env.REACT_APP_API_IMAGES}/${item.img}`,
              desc: item.title
            });
          });
          setProductList(arrayList);
        } else
          message.error(
            "Ha ocurrido un error  al cargar los productos de esta acción"
          );
    }
  }, [isLoading, isFetching]);

  const addProductToAction = async () => {
    try {
      const response = await addProductToActionMutation.mutateAsync({
        idAction,
        idProduct: selectedProduct[0].id
      });
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        refetch("listGameCodeProducts");
        refetchAction("listGameCodeAction");
        message.success(response.body);
        setSelectedProduct(null);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setSelectedProduct(null);
    }
  };
  useEffect(() => {
    if (!!selectedProduct) {
      addProductToAction();
    }
  }, [selectedProduct]);
  const deleteProductFromAction = useMutation(deleteGameCodeProducts);
  const handleItemDelete = async record => {
    try {
      const response = await deleteProductFromAction.mutateAsync({
        idAction,
        idProduct: record
      });
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
        refetch("listGameCodeProducts");
        refetch("refetchAction");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title="Agregar productos"
    >
      <Form
        {...formItemLayout}
        name="advanced_search"
        className="ant-advanced-search-form"
      >
        <FormItem name={`product`} label={`Nombre del producto `}>
          <SearchInput
            placeholder="Indique el producto"
            setSelectedValue={data => setSelectedProduct(data)}
            route="game-code"
          />
        </FormItem>
        <div className="scroll-modal">
          {isLoading && (
            <div className="gx-loader-view">
              <LoadingOutlined className="gx-fs-icon-lg" />
            </div>
          )}
          {productList.length === 0 ? (
            <div className="gx-loader-view">
              <span className="gx-text-light-grey">
                No hay productos agregados
              </span>
            </div>
          ) : (
            productList.map(item => (
              <div
                key={item.key}
                className="gx-d-flex gx-justify-content-around gx-border gx-align-items-center gx-rounded-base gx-border-primary gx-py-2 gx-mb-3"
              >
                <Image width={100} src={item.src} />
                <span style={{ width: "50%" }} className="gx-fs-sm">
                  {item.desc}
                </span>
                <DeleteOutlined
                  className="gx-link gx-fs-md"
                  onClick={() =>
                    DeleteModal(item.id, "este producto", handleItemDelete)
                  }
                />
              </div>
            ))
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddProduct;
