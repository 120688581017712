import React, { useEffect, useState } from "react";
import TableCodes from "./TableCodes";
import ModalAddAction from "./ModalAddAction";
import { listGameCodeAction } from "../../services/querys/codes";
import { useQuery } from "react-query";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import { message } from "antd";

const Codes = () => {
  const [isVisibleAddAction, setIsVisibleAddAction] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagination, setTotalPagination] = useState(0);
  const [actionsList, setActionsList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [actionSelected, setActionSelected] = useState([]);
  const [action, setAction] = useState("");
  const enabled =
    window.location.pathname === "/codigos" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  // Listado de acciones
  const { isLoading, data, isFetching, refetch } = useQuery(
    ["listGameCodeAction", currentPage, searchInput],
    () => listGameCodeAction(searchInput, currentPage),
    { enabled, keepPreviousData: true, staleTime: 5000 }
  );
  useEffect(() => {
    if (!isLoading) {
      let arrayList = [];
      if (data)
        if (data.body.data) {
          data.body.data.map(item => {
            const { id, name, startDate, endDate, units } = item;
            return arrayList.push({
              key: id,
              id: id,
              actionName: name,
              startDate,
              endDate,
              associatedProducts: units.product,
              availableCode: units.codes.availables,
              pendingCode: units.codes.pending
            });
          });
          setTotalPagination(data.body.metadata.total);
          setActionsList(arrayList);
        } else message.error("Ha ocurrido un error en envío gratis");
    }
  }, [isLoading, isFetching]);
  return (
    <>
      <TableCodes
        data={actionsList}
        onClickAddAction={() => {
          setIsVisibleAddAction(true);
          setAction("create");
        }}
        setTableIndex={data => setCurrentPage(data)}
        totalPagination={totalPagination}
        refetch={refetch}
        setSearchInput={data => setSearchInput(data)}
        tableIndex={currentPage}
        setAction={data => setAction(data)}
        setActionSelected={data => setActionSelected(data)}
      />

      {isVisibleAddAction && (
        <ModalAddAction
          action={action}
          isVisible={isVisibleAddAction}
          setIsVisible={data => setIsVisibleAddAction(data)}
          refetch={refetch}
          actionSelected={actionSelected}
          setActionSelected={data => setActionSelected(data)}
        />
      )}
    </>
  );
};

export default Codes;
