import {
  TOKEN_STORAGE_KEY,
  USER_DATA_STORAGE_KEY
} from "../../constants/LocalStorageKeys";

const request = async ({ method = "GET", endpoint }) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/${endpoint}`,
    {
      method,
      headers: {
        "Content-Type": "application/problem+json",
        Authorization: `Bearer ${token}`
      }
    }
  )
    .then(r =>
      r.json().then(data => ({ status: r.status, body: data, ok: r.ok }))
    )
    .then(obj => obj);

  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem(USER_DATA_STORAGE_KEY);
    localStorage.setItem("isTokenExpired", true);
  } else return response;
};
export default request;
