import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const HelpInfo = ({ text }) => {
  return (
    <Tooltip title={text} className="gx-cursor-help">
      <QuestionCircleOutlined className="gx-ml-1 gx-text-blue gx-fs-xs " />
    </Tooltip>
  );
};

export default HelpInfo;
