import React, { useEffect, useState } from "react";
import { Card, Table, Button, Col, Row, Input, message, Select } from "antd";
import moment from "moment";
import { Image } from "antd";
import "moment/locale/es";
import {
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import DeleteModal from "../../components/Modal/DeleteModal";
import { useMutation } from "react-query";
import { deleteIFlash } from "../../services/mutations/instantFlash";
import { useAuth } from "../../context/auth-context";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";
const { Option } = Select;

const TableFlash = ({
  data,
  buttonTitle,
  onClickModal,
  setIsVisible,
  setAction,
  setInstantFlashSelected,
  loading = false,
  totalPagination,
  setTableIndex,
  refetch,
  setSearchInput,
  tableIndex
}) => {
  const TODAY = new moment();
  const obtainRemaining = record => {
    let states = { noIniciado: false, enCurso: false, finalizado: false };
    var y = new moment(record.endDate);
    var w = new moment(record.startDate);
    const endDateBetweenToday = TODAY.diff(y, "minutes");
    const startDateBetweenToday = TODAY.diff(w, "minutes");
    if (startDateBetweenToday < 0) states.noIniciado = true;
    else if (endDateBetweenToday < 0) {
      var duration = new moment.duration(y.diff(TODAY));

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();

      states.enCurso = { days, hours, minutes };
    } else states.finalizado = true;
    return states;
  };
  const columns = [
    {
      title: "",
      dataIndex: "image",
      width: 60,
      render: text => <Image width={50} src={text} />
    },
    {
      title: "Producto",
      dataIndex: "productName",
      width: 360,
      key: "productName"
    },
    {
      title: "Stock",
      dataIndex: "stock",
      width: 30,
      key: "stock",
      render: (text, _) => (
        <span>
          {text ? (
            <CheckOutlined className="gx-text-success gx-fs-lg" />
          ) : (
            <CloseCircleOutlined className="gx-text-danger gx-fs-lg" />
          )}
        </span>
      )
    },
    {
      title: <CardHelpInfo texto={helpTexts.limite} />,
      width: 30,
      dataIndex: "unitsLimit",
      key: "unitsLimit"
    },
    {
      title: "Descuento",
      dataIndex: "discount",
      width: 30,
      key: "discount",
      render: (text, _) => (
        <span>
          {text} %
        </span>
      )
    },
    {
      title: "Inicia",
      dataIndex: "startDate",
      width: 220,
      key: "startDate",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new moment(a.startDate).format("YYYYMMDDHHmm") -
        new moment(b.startDate).format("YYYYMMDDHHmm"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => (
        <span>{moment(text).format("DD-MM-YYYY HH:mm")}</span>
      )
    },
    {
      title: "Finaliza",
      dataIndex: "endDate",
      width: 220,
      key: "endDate",
      sorter: (a, b) =>
        new moment(a.endDate).format("YYYYMMDDHHmm") -
        new moment(b.endDate).format("YYYYMMDDHHmm"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => (
        <span>{moment(text).format("DD-MM-YYYY HH:mm")}</span>
      )
    },
    {
      title: "Duración",
      dataIndex: "duration",
      width: 120,
      key: "duration",
      render: (_, record) => {
        var y = moment(record.startDate);
        var x = moment(record.endDate);
        var duration = moment.duration(x.diff(y));
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        return (
          <span>{`${
            days > 0 ? `${days === 1 ? `${days} día` : `${days} días`}, ` : ""
          } ${hours > 0 ? `${hours}h` : ""} ${
            minutes > 0 ? `${minutes}min` : ""
          } `}</span>
        );
      }
    },

    {
      title: "Estado",
      dataIndex: "remaining",
      width: 120,
      key: "remaining",
      render: (_, record) => {
        const remaining = obtainRemaining(record);
        if (remaining.noIniciado)
          return (
            <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
              <span className="ant-badge-status-dot gx-mr-1 ant-badge-status-yellow"></span>{" "}
              <span> No ha iniciado</span>
            </div>
          );
        else if (remaining.enCurso) {
          /*
          const days = remaining.enCurso.days;
          const hours = remaining.enCurso.hours;
          const minutes = remaining.enCurso.minutes;
          return (
            <span>{`${
              days > 0 ? `${days === 1 ? `${days} día` : `${days} días`}, ` : ""
            } ${hours > 0 ? `${hours}h` : ""} ${
              minutes > 0 ? `${minutes}min` : ""
            } `}</span>
          );*/
          return (
            <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
              <span className="ant-badge-status-dot gx-mr-1 ant-badge-status-success"></span>
              <span> En curso</span>
            </div>
          );
        } else
          return (
            <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
              <span className="ant-badge-status-dot gx-mr-1 ant-badge-status-red"></span>{" "}
              <span>Finalizado</span>
            </div>
          );
      }
    },

    {
      key: "action",
      width: 50,
      render: (_, record) => {
        var w = new moment(record.startDate);
        const startDateBetweenToday = TODAY.diff(w, "minutes");
        return (
          <div className="gx-d-flex gx-align-items-center">
            <EditOutlined
              className={` gx-fs-xl ${
                startDateBetweenToday > 0
                  ? "gx-text-light-grey gx-cursor-default"
                  : "gx-link"
              }`}
              onClick={() => {
                if (startDateBetweenToday < 0) {
                  setIsVisible(true);
                  setAction("edit");
                  setInstantFlashSelected(record);
                }
              }}
            />
            <DeleteOutlined
              className="gx-link gx-fs-xl gx-ml-2"
              onClick={() =>
                DeleteModal(
                  record.key,
                  "este precio relámpogo",
                  handleIFlashDelete
                )
              }
            />
          </div>
        );
      }
    }
  ];
  const auth = useAuth();
  const [iFlashData, setIFlashData] = useState([]);
  const [statusState, setStatusState] = useState("enCurso");
  const deleteIFlashMutation = useMutation(deleteIFlash);
  const handleIFlashDelete = async record => {
    try {
      const response = await deleteIFlashMutation.mutateAsync(record);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
        refetch("listIFlash");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      const filtered = data.filter(record => {
        const remaining = obtainRemaining(record);
        return !!remaining[statusState];
      });
      if (filtered.length > 0) {
        setIFlashData(filtered);
      } else {
        setIFlashData(data);
        setStatusState("todas");
      }
    }
  }, [data]);

  const filterByRemainig = type => {
    //TODO: cambiar despues
    const filtered = data.filter(record => {
      const remaining = obtainRemaining(record);
      setStatusState(type);
      return !!remaining[type];
    });
    if (type === "todas") setIFlashData(data);
    else setIFlashData(filtered);
  };
  return (
    <Card>
      <Row>
        <Col xl={4} lg={4} md={4} sm={4} xs={12} className="gx-d-flex gx-mb-4">
          <Button type="primary" onClick={onClickModal}>
            {buttonTitle}
          </Button>
        </Col>
        <Col
          xl={13}
          lg={13}
          md={4}
          sm={4}
          xs={12}
          className="gx-d-flex gx-mb-4"
        >
          <Select
            style={{ minWidth: 120 }}
            value={statusState}
            className="remaining"
            onChange={value => {
              filterByRemainig(value);
            }}
          >
            <Option value="enCurso">En Curso</Option>
            <Option value="finalizado">Finalizado</Option>
            <Option value="noIniciado">Por iniciar</Option>
            <Option value="todas">Todas</Option>
          </Select>
        </Col>
        <Col className="gx-mb-4" xl={7} lg={7} md={16} sm={16} xs={24}>
          <Input
            id="search-i-flash"
            placeholder="busqueda por nombre"
            onChange={e => {
              const inputValue = e.target.value;
              setSearchInput(inputValue);
              setTableIndex(1);
            }}
            allowClear
            className="gx-pl-3"
          />
        </Col>
      </Row>

      <Table
        className="gx-table-responsive components-table-demo-nested"
        columns={columns}
        dataSource={iFlashData}
        locale={{
          emptyText: "No hay productos agregados"
        }}
        loading={loading}
        pagination={{
          onChange: current => {
            if (totalPagination) {
              setTableIndex(current);
            }
          },
          current: tableIndex,
          total: totalPagination,
          defaultPageSize: 30,
          position: [totalPagination > 30 ? "topRigth" : "none", "bottomRight"]
        }}
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
      />
    </Card>
  );
};

export default TableFlash;
