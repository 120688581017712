import React, { useState, useEffect } from "react";
import { Card, Button, Col, Row, Switch, Tooltip, Input, message } from "antd";
import TableDrag from "./TableDrag";
import "moment/locale/es";
import UploadPicture from "../../components/Upload/UploadPicture";
import ModalImage from "./ModalImage";
import { FormOutlined, SaveOutlined } from "@ant-design/icons";
import { useAuth } from "../../context/auth-context";
import { store } from "../../services/mutations/myStore";
import { useMutation, useQuery } from "react-query";
import { getMyStore, getBanners } from "../../services/querys/myStore";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";

const MyStore = () => {
  const [isVisibleBanner, setIsVisibleBanner] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isChangingTitle, setIsChangingTitle] = useState(true);
  const { user } = useAuth();
  const [titleName, setTitleName] = useState(user.name);
  const [myAvatar, setMyAvatar] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [listBanner, setListBanner] = useState([]);
  const auth = useAuth();
  const enabled =
    window.location.pathname === "/mi-tienda" &&
    !!localStorage.getItem(TOKEN_STORAGE_KEY);

  //variable My Store requests
  const myStoreMutation = useMutation(store);
  const myStoreQuery = useQuery("myStore", () => getMyStore(), {
    // The query will not execute until the userId exists
    enabled
  });

  //variable Banners request
  const {
    isLoading: isLoadingBanner,
    error: errorBanner,
    data: dataBanner,
    refetch: refetchBanner,
    isFetching: isFetchingBanner
  } = useQuery("banners", () => getBanners(), {
    // The query will not execute until the userId exists
    enabled,
    // state cache
    keepPreviousData: true,
    staleTime: 600000
  });

  const successBanner = !errorBanner && !isLoadingBanner;
  // Datos de la tienda
  useEffect(() => {
    if (!myStoreQuery.isLoading && myStoreQuery.data) {
      if (myStoreQuery.data.body) {
        const { id, active, name, avatar } = myStoreQuery.data.body;
        setMyAvatar([
          {
            uid: id,
            name: `${name}`,
            status: "done",
            url: `${process.env.REACT_APP_API_IMAGES}/${avatar}`
          }
        ]);
        setTitleName(name);
        setDisabled(active);
      }
    }
  }, [myStoreQuery.isLoading, myStoreQuery.data]);
  // Lista de banners

  useEffect(() => {
    if (successBanner && dataBanner) {
      let arrayList = [];
      if (dataBanner.body) {
        dataBanner.body.map(item => {
          const { id, checksum, order, active } = item;
          return arrayList.push({
            order,
            key: `${order}-banner`,
            src: `${process.env.REACT_APP_API_IMAGES}/${checksum}`,
            index: id,
            active
          });
        });
        setListBanner(arrayList);
      }
    }
  }, [successBanner, isFetchingBanner]);
  useEffect(() => {
    refetchBanner("banners");
  }, []);

  // Actualizar datos de la tienda
  const onFinish = async (type, switchValue) => {
    let variables = {};
    if (type === "name") variables["name"] = titleName;
    else if (type === "active") {
      setDisabled(switchValue);
      variables["active"] = switchValue ? "1" : "0";
    }
    try {
      const response = await myStoreMutation.mutateAsync(variables);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        if (type === "name") setTitleName(user.name);
        else setDisabled(true);
        message.error(response.body);
      } else {
        if (type === "name") {
          message.success("Nombre cambiado satisfactoriamente");
          setIsChangingTitle(true);
        } else
          message.success(
            `Se ha ${switchValue ? "" : "des"}habilitado satisfactoriamente`
          );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Card>
      <Row>
        <Col
          xxl={16}
          xl={14}
          lg={12}
          md={12}
          sm={8}
          xs={24}
          className="gx-d-flex gx-mb-4"
        >
          <UploadPicture
            maxItems={1}
            title="logo"
            files={myAvatar}
            fileList={fileList}
            setFileList={data => setFileList(data)}
          />
        </Col>
        <Col
          className="gx-mb-4 gx-d-flex gx-flex-column gx-justify-content-around"
          xxl={8}
          xl={10}
          lg={12}
          md={12}
          sm={16}
          xs={24}
        >
          <Row>
            <Col xs={24} md={11} lg={10} xl={10} className="gx-text-primary">
              {<CardHelpInfo texto={helpTexts.nombreTienda} />}
            </Col>
            <Col
              xs={24}
              md={13}
              lg={14}
              xl={14}
              className="gx-d-flex gx-align-items-center "
            >
              <Input
                disabled={isChangingTitle}
                size="small"
                value={titleName}
                className={isChangingTitle ? "gx-bg-white gx-border-0" : ""}
                onChange={e => setTitleName(e.target.value)}
              />
              {isChangingTitle ? (
                <Tooltip title="Editar">
                  <FormOutlined
                    className="gx-fs-lg gx-link gx-ml-2"
                    onClick={() => setIsChangingTitle(false)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Guardar">
                  <SaveOutlined
                    onClick={() => onFinish("name", false)}
                    className="gx-fs-lg gx-link  gx-ml-2"
                  />
                </Tooltip>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={12} lg={12} xl={12} className="gx-text-primary">
              {<CardHelpInfo texto={helpTexts.habilitar} />}
            </Col>
            <Col span={12} xl={12}>
              <Tooltip title={disabled ? "Habilitada" : "Deshabilitada"}>
                <Switch
                  checked={disabled}
                  onChange={value => onFinish("active", value)}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xl={17}
          lg={17}
          md={8}
          sm={8}
          xs={24}
          className="gx-d-flex gx-mb-4"
        >
          <Button type="primary" onClick={() => setIsVisibleBanner(true)}>
            Agregar banner
          </Button>
        </Col>
      </Row>

      <ModalImage
        isVisible={isVisibleBanner}
        setIsVisible={data => setIsVisibleBanner(data)}
        refetchBanner={refetchBanner}
      />
      <TableDrag
        dataSource={listBanner}
        setDataSource={data => setListBanner(data)}
        isLoading={isFetchingBanner}
        refetchBanner={refetchBanner}
      />
    </Card>
  );
};

export default MyStore;
