import React from "react";

import Aux from "util/Auxiliary";

const LineIndicator = ({
  title,
  width,
  value,
  color,
  color2,
  value2,
  width2
}) => {
  return (
    <Aux>
      <div className="ant-row-flex gx-align-items-center">
        <p className="gx-fs-lg gx-font-weight-light ">{title}</p>
      </div>
      <div className="gx-line-indi-info">
      <span className="gx-line-indi-count gx-mr-2 gx-font-weight-light ">
          Visitas
        </span>
        <div
          className={`gx-line-indi gx-bg-${color}`}
          style={{
            width: Number.parseInt(width, 10) * 4
          }}
        />
        <span className="gx-line-indi-count gx-ml-2 gx-font-weight-light ">
          {value}
        </span>
      </div>
      <div className="gx-line-indi-info">
        <span className="gx-line-indi-count gx-mr-2 gx-font-weight-light ">
          Conversiones
        </span>
        <div
          className={`gx-line-indi gx-bg-${color2}`}
          style={{
            width: Number.parseInt(width2, 10) * 4
          }}
        />
        <span className="gx-line-indi-count gx-ml-2 gx-font-weight-light ">
          {value2}
        </span>
      </div>
    </Aux>
  );
};

export default LineIndicator;
