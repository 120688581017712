import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { Button, Input, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../../context/auth-context";
import { useMutation } from "react-query";
import { login } from "../../services/mutations/login";

import axios from "axios";
import md5 from "md5";
import {
  TOKEN_STORAGE_KEY,
  USER_DATA_STORAGE_KEY
} from "../../constants/LocalStorageKeys";
import { LoadingOutlined } from "@ant-design/icons";
import { deviceDetect } from "react-device-detect";
const { Text } = Typography;
const FormItem = Form.Item;

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const { setUserData } = useAuth();
  const [form] = Form.useForm();
  const loginMutation = useMutation(login);

  useEffect(() => {
    const isTokenExpired = localStorage.getItem("isTokenExpired");
    if (isTokenExpired) {
      message.info("Su sesión ha expirado, por favor inicie sesión nuevamente");
    }
    localStorage.clear();
  }, []);
  const history = useHistory();
  const onFinish = async values => {
    const ip = await axios.get("https://icanhazip.com");
    setIsLoading(true);
    try {
      const response = await loginMutation.mutateAsync({
        email: values.email,
        password: md5(values.password),
        agent: `os:${deviceDetect().osName} - version:${
          deviceDetect().osVersion
        }, browser: ${deviceDetect().browserName}`,
        ip: ip.data
      });

      if (!response.ok) {
        setErrors(response.body);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        const body = response.body;
        setUserData({
          token: body.token,
          user: body.user
        });

        await localStorage.setItem(TOKEN_STORAGE_KEY, body.token);
        await localStorage.setItem(
          USER_DATA_STORAGE_KEY,
          JSON.stringify(body.user)
        );
        history.push("/estadisticas");
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
      setErrors("Ha ocurrido un error, intente de nuevo en unos minutos");
    }
  };
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <img alt="example" src={require("assets/images/lo-white.svg")} />
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              form={form}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "El correo electrónico no es valido."
                  },
                  {
                    required: true,
                    message: "Introduzca su correo electrónico."
                  }
                ]}
              >
                <Input placeholder="Correo electrónico" />
              </FormItem>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Introduzca su contraseña."
                  }
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  autoComplete="on"
                />
              </FormItem>
              <FormItem>
                <Link to="/forgot-password" className="gx-ml-2">
                  ¿Olvidaste tu contraseña?
                </Link>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </FormItem>
            </Form>
            {errors && (
              <Text key={`errorLogin`} type="danger">
                {errors}
              </Text>
            )}
          </div>
          {isLoading && (
            <div className="gx-loader-view">
              <LoadingOutlined className="gx-fs-icon-lg loading" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
