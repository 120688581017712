import request from "../utils/request";
export const getStatisticsStore = (start, end) => {
  return request({
    endpoint: `statistics/stores?startDate=${start}&endDate=${end}`
  });
};

export const getStatisticsProducts = (start, end) => {
  return request({
    endpoint: `statistics/products?startDate=${start}&endDate=${end}`
  });
};

export const getStatisticsCategories = (start, end) => {
  return request({
    endpoint: `statistics/categories?startDate=${start}&endDate=${end}`
  });
};
