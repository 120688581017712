import request from "../utils/request";
import requestBody from "../utils/requestBody";

export const user = () => {
  return request({
    endpoint: "auth/user"
  });
};

export const updateUser = data => {
  return requestBody({
    method: "PUT",
    endpoint: "auth/user",
    data
  });
};
