import { Modal } from "antd";
const confirm = Modal.confirm;
const DeleteModal = (record, title, handler) => {
  confirm({
    title: `¿Está seguro que desea eliminar ${title}?`,
    content: "Esta acción no se podrá revertir",
    okText: "Si",
    okType: "danger",
    cancelText: "No",
    centered: true,
    okButtonProps: { danger: true, className: "danger-button" },
    onOk() {
      handler(record);
    }
  });
};
export default DeleteModal;
