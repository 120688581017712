import React, { useEffect, useState } from "react";
import { message, Select } from "antd";
import { listProduct } from "../../services/mutations/products";
import { useQuery } from "react-query";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";

const { Option } = Select;

const SearchInput = props => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");

  const handleSearch = value => {
    if (value) {
      setValue(value);
    } else {
      setData([]);
    }
  };

  const handleChange = (value, text) => {
    setValue(value);
    props.setSelectedValue(data.filter(item => item.title === text.children));
  };
  const enabled =
    !!localStorage.getItem(TOKEN_STORAGE_KEY) && value.length >= 3;
  const productQuery = useQuery(
    ["listProduct", value, props.route],
    () => listProduct(value, props.route),
    {
      // The query will not execute until the userId exists
      enabled
    }
  );

  useEffect(() => {
    if (!productQuery.isLoading) {
      let arrayList = [];
      if (!!value && productQuery.data) {
        if (productQuery.data.status === 500) {
          message.error("Ha ocurrido un error");
        } else if (!!productQuery.data) {
          productQuery.data.body.map(item => {
            const { id, internalId, stock, title, img } = item;
            return arrayList.push({
              key: id,
              id: id,
              internalId,
              stock,
              title,
              img: `${process.env.REACT_APP_API_IMAGES}/${img}`
            });
          });
          setData(arrayList);
        }
      }
    }
  }, [productQuery.isLoading, value]);
  const options = data.map(d => <Option key={d.key}>{d.title}</Option>);
  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  );
};

export default SearchInput;
