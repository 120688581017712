import React, { useState } from "react";
import { Button, Form, Image, Modal, Row, Col, message } from "antd";

import SearchInput from "../../components/DataEntry/SearchInput";
import { useAuth } from "../../context/auth-context";
import { addFreeShipping } from "../../services/mutations/freeShipping";
import withLoaderButton from "../../hocs/StyledButton";
import { useMutation } from "react-query";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 }
  }
};

const ModalAddItem = ({ setIsVisible, isVisible, refetch }) => {
  const [form] = Form.useForm();
  const LoaderButton = withLoaderButton(Button);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const uploadFreeShippingMutation = useMutation(addFreeShipping);
  const auth = useAuth();
  const [errors, setErrors] = useState();
  const onFinish = async () => {
    setIsLoading(true);

    if (!selectedProduct) {
      form.setFields([
        {
          name: "product",
          errors: ["Complete los campos."]
        }
      ]);
      setIsLoading(false);
      return;
    }

    try {
      const response = await uploadFreeShippingMutation.mutateAsync({
        idProduct: selectedProduct[0].id
      });
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        setErrors(response.body);
      } else {
        refetch("listFreeShipping");
        setIsLoading(false);
        setIsVisible(false);
        message.success("Instant flash creado satisfactoriamente");
        setSelectedProduct("");
        form.resetFields();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      maskClosable={false}
      visible={isVisible}
      footer={null}
      onCancel={() => {
        setIsVisible(false);
        setSelectedProduct("");
      }}
      centered
      title={`Agregar producto `}
    >
      <Form
        form={form}
        {...formItemLayout}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <FormItem name={`product`} label={`Nombre del producto `}>
          <SearchInput
            placeholder="Indique el producto"
            setSelectedValue={data => setSelectedProduct(data)}
            route="free-shipping"
          />
        </FormItem>
        {selectedProduct && (
          <div className="product-selected  gx-py-3 gx-mb-3">
            <Image
              width={100}
              src={`${process.env.REACT_APP_API_IMAGES}/${selectedProduct[0].img}`}
            />
            <span>{selectedProduct[0].title}</span>
          </div>
        )}
        {errors && <div className="gx-text-danger">{errors}</div>}
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button
                type="secondary"
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <LoaderButton isLoading={isLoading}>Guardar</LoaderButton>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddItem;
