import React from "react";
import HelpInfo from "./HelpInfo";

const CardHelpInfo = ({ texto }) => {
  return (
    <div className="gx-d-flex gx-align-items-center">
      <span>{texto.title}</span>
      <HelpInfo text={texto.text} />
    </div>
  );
};

export default CardHelpInfo;
