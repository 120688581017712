import request from "../utils/request";
import requestBody from "../utils/requestBody";
export const listIFlash = (search, pageNumber) => {
  return request({
    endpoint: `/instant-flash/?search=${search}&page=${pageNumber}`
  });
};

export const addInstantF = variables => {
  return requestBody({
    method: variables[2],
    endpoint: `instant-flash/${variables[1]}`,
    data: variables[0]
  });
};

export const deleteIFlash = id => {
  return request({
    method: "DELETE",
    endpoint: `instant-flash/${id}`
  });
};

export const getByIdIFlash = id => {
  return request({
    endpoint: `instant-flash/${id}`
  });
};
