import request from "../utils/request";
export const getMyStore = () => {
  return request({
    endpoint: "stores/my-store/"
  });
};

export const getBanners = () => {
  return request({
    endpoint: "banners/store/"
  });
};
