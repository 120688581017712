import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

const withLoaderButton = Button => {
  return props => {
    return (
      <Button type="primary" htmlType="submit" disabled={props.isLoading}>
        <div
          className="gx-d-flex gx-h-100 gx-justify-content-center gx-align-items-center"
          style={{
            width: "55px"
          }}
        >
          {props.isLoading ? (
            <LoadingOutlined className="gx-fs-xl" />
          ) : (
            props.children
          )}
        </div>
      </Button>
    );
  };
};

export default withLoaderButton;
