import React, { useState } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";

import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { logout } from "../../services/mutations/logout";
import { useMutation, useQuery } from "react-query";
import ModalConfig from "./ModalConfig";
import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";
import { getMyStore } from "../../services/querys/myStore";
import moment from "moment";
const TODAY = new moment();
const UserInfo = () => {
  const { setUserData, setDates } = useAuth();
  const enabled = !!localStorage.getItem(TOKEN_STORAGE_KEY);
  const { data, isLoading } = useQuery("myStore", () => getMyStore(), {
    // The query will not execute until the userId exists
    enabled
  });
  const logoutMutation = useMutation(logout);
  const [isVisibleSetting, setIsVisibleSetting] = useState(false);
  const signOut = async () => {
    try {
      const response = await logoutMutation.mutateAsync();
      if (!response.ok) {
        console.error(response.body);
      } else {
        localStorage.clear();
        setUserData({ token: null, user: null });
        setDates([TODAY, TODAY]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => signOut()}>
        <Link to="/signin" className="gx-d-flex gx-justify-content-between">
          <span>Cerrar sesión</span>{" "}
          <i className="icon icon-signin gx-ml-1 gx-link"></i>
        </Link>
      </li>
      <li
        className="gx-link gx-d-flex gx-justify-content-between"
        onClick={() => setIsVisibleSetting(true)}
      >
        <span>Configuración</span>{" "}
        <i className="icon icon-setting gx-ml-1 gx-fs-lg"></i>
      </li>
    </ul>
  );

  return (
    <>
      {" "}
      <Popover
        overlayClassName="gx-popover-horizantal "
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={`${
            isLoading
              ? require("assets/images/0.svg")
              : data
              ? data.body.avatar
                ? process.env.REACT_APP_API_IMAGES + "/" + data.body.avatar
                : require("assets/images/0.svg")
              : require("assets/images/0.svg")
          }`}
          className="gx-avatar gx-pointer gx-ml-3"
          alt=""
        />
      </Popover>
      <ModalConfig
        isVisible={isVisibleSetting}
        setIsVisible={data => setIsVisibleSetting(data)}
      />
    </>
  );
};

export default connect(null, { userSignOut })(UserInfo);
