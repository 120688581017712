import { TOKEN_STORAGE_KEY } from "../../constants/LocalStorageKeys";

 const requestFormData = ({ method = "GET", endpoint, formData={} }) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  return fetch(`${process.env.REACT_APP_API_HOST}/${endpoint}`, {
    method,
    headers: {
        Authorization: `Bearer ${token}`,
       
    },
    body: formData
  })
    .then(r =>
      r.json().then(data => ({ status: r.status, body: data, ok: r.ok }))
    )
    .then(obj => obj);
};
export default requestFormData