import React from "react";
import { Table, Image, message, Tooltip, Switch } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import { DeleteOutlined, HomeOutlined, MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import DeleteModal from "../../components/Modal/DeleteModal";
import {
  deleteBanner,
  orderBanner,
  toggleActiveBanner
} from "../../services/mutations/myStore";
import { useMutation } from "react-query";
import { useAuth } from "../../context/auth-context";
import CardHelpInfo from "../../components/dataDisplay/Tooltip/CardHelpInfo";
import helpTexts from "../../util/helpTexts";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const SortableTable = ({
  dataSource,
  setDataSource,
  isLoading,
  refetchBanner
}) => {
  
  const enableBannerMutation = useMutation(toggleActiveBanner);

  // Actualizar datos de la tienda
  const handleEnable = async (switchValue, id) => {
     try {
      const response = await enableBannerMutation.mutateAsync(id);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response);
      }
      else {
        refetchBanner("banners");
        message.success(
          `Banner ${switchValue ? "" : "des"}habilitado satisfactoriamente`
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const columns = [
    {
      title: <CardHelpInfo texto={helpTexts.ordenar} />,
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: (text, record) => {
        if (record.order === 1)
          return (
            <div className="gx-d-flex gx-justify-content-center gx-flex-column">
              <HomeOutlined />
              <p className="gx-text-center gx-fs-sm">Portada</p>
            </div>
          );
        else
          return (
            <div className="gx-d-flex gx-justify-content-center ">
              <DragHandle />
            </div>
          );
      }
    },
    {
      title: <CardHelpInfo texto={helpTexts.banner} />,
      dataIndex: "src",
      className: "drag-visible",
      render: text => (
        <Image
          wrapperStyle={{ height: "auto !important", width: 200 }}
          width={200}
          src={text}
        />
      )
    },
    {
      title: "Habilitar",
      dataIndex: "active",
      width: 50,
      render: (text, record) => (
        <Tooltip
          //title="Acción no disponible"
          title={text ? "Habilitada" : "Deshabilitada"}
        >
          <Switch
            //TODO change for value
            checked={text}
            size="small"
            onChange={value => handleEnable(value, record.index)}
            
          />
        </Tooltip>
      )
    },
    {
      key: "action",
      width: 50,
      render: (_, record) => (
        <DeleteOutlined
          className="gx-link gx-fs-xl"
          onClick={() =>
            DeleteModal(record.index, "este banner", handleBannerDelete)
          }
        />
      )
    }
  ];
  const orderBannerMutation = useMutation(orderBanner);
  const deleteBannerMutation = useMutation(deleteBanner);
  const auth = useAuth();

  const orderMutationFunc = async orderArray => {
    try {
      const response = await orderBannerMutation.mutateAsync({
        itemsIds: orderArray
      });
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter(el => !!el);

      setDataSource(newData);
      const orderArray = [];
      // para preservar que el icono casa siempre sea el primero
      newData.map((item, index) => (item.order = index + 1));

      newData.map(item => orderArray.push(item.index));
      console.log(newData);
      orderMutationFunc(orderArray);
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      x => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  const handleBannerDelete = async record => {
    try {
      const response = await deleteBannerMutation.mutateAsync(record);
      if (!response.ok && response.status === 401)
        auth.setUserData({ token: null, user: null });
      else if (!response.ok) {
        message.error(response.body);
      } else {
        message.success(response.body);
        refetchBanner("banners");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <Table
      pagination={{ defaultPageSize: 5 }}
      scroll={{ x: window.innerWidth < 400 ? 400 : false }}
      className="gx-table-responsive components-table-demo-nested"
      dataSource={dataSource}
      columns={columns}
      loading={isLoading}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
    />
  );
};

export default SortableTable;
