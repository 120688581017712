import requestFormData from "../utils/requestFormData";
import requestBody from "../utils/requestBody";
import request from "../utils/request";
export const avatarForm = formData => {
  return requestFormData({
    method: "POST",
    endpoint: "stores/my-store/update",
    formData
  });
};

export const store = data => {
  return requestBody({
    method: "POST",
    endpoint: "stores/my-store/update",
    data
  });
};
export const orderBanner = data => {
  return requestBody({
    method: "PUT",
    endpoint: "banners/order",
    data
  });
};
export const bannerForm = formData => {
  return requestFormData({
    method: "POST",
    endpoint: "banners/store/",
    formData
  });
};
export const deleteBanner = id => {
  return request({
    method: "DELETE",
    endpoint: `banners/${id}`
  });
};

export const toggleActiveBanner = id => {
  return request({
    method: "PUT",
    endpoint: `banners/${id}/toggle-active`
  })
}