import React from "react";

import Widget from "components/Widget/index";
import { connect } from "react-redux";
import { THEME_TYPE_DARK } from "../../constants/ThemeSetting";
import helpTexts from "../../util/helpTexts";
import CardHelpInfo from "../dataDisplay/Tooltip/CardHelpInfo";

const IconWithTextCard = props => {
  const { icon, title, subTitle, pending = false } = props;
  let { iconColor } = props;
  if (props.themeType === THEME_TYPE_DARK) {
    iconColor = "white";
  }

  return (
    <Widget styleName="card-balances">
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-lg-4 gx-mr-3">
          <i
            className={`icon icon-${icon} gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
            style={{ fontSize: 45 }}
          />
        </div>
        <div
          className="gx-media-body gx-justify-content-between gx-d-flex gx-flex-column"
          style={{ height: 60 }}
        >
          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-0">{title}</h1>
          <p className="gx-text-grey gx-mb-0">{subTitle}</p>

          <p className="gx-text-light-grey gx-mb-0 gx-fs-sm gx-d-flex">
            {pending && (
              <>
                <span className="gx-mr-2">{pending}</span>
                <CardHelpInfo texto={helpTexts.pendiente} />{" "}
              </>
            )}
          </p>
        </div>
      </div>
    </Widget>
  );
};

const mapStateToProps = ({ settings }) => {
  const { themeType } = settings;
  return { themeType };
};
export default connect(mapStateToProps, null)(IconWithTextCard);
