import React, { Component } from "react";
import { Layout, ConfigProvider } from "antd";
import es_ES from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";

import { connect } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const { Content, Footer } = Layout;

export class MainApp extends Component {
  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  render() {
    const { match, navStyle } = this.props;

    return (
      <ConfigProvider locale={es_ES}>
        <Layout className="gx-app-layout">
          <Sidebar />
          <Layout>
            <Topbar />
            <Content
              className={`gx-layout-content ${this.getContainerClass(
                navStyle
              )} `}
            >
              <App match={match} />
              <Footer>
                <div className="gx-layout-footer-content">{footerText}</div>
              </Footer>
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings;
  return { width, navStyle };
};
export default connect(mapStateToProps)(MainApp);
